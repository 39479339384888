import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
// @mui
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'

import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'

// types
// components
import { useSnackbar } from 'src/components/snackbar'
import FormProvider, { RHFTextField, RHFUploadAvatar } from 'src/components/hook-form'
import axiosInstance from 'src/utils/axios'

import { EditPackageSchema, NewPackageSchema } from 'src/validators/package.schemas'
import { Grid, Typography } from '@mui/material'
import { IPackageItem } from 'src/types/package'
import { RHFCheckbox } from 'src/components/hook-form/rhf-checkbox'
import { fData } from 'src/utils/format-number'
import RHFAutocomplete from 'src/components/hook-form/rhf-autocomplete'
import RHFEditor from 'src/components/hook-form/rhf-editor'

type Props = {
  open: boolean
  onClose: VoidFunction
  onRefleshTable: VoidFunction
  currentUser?: IPackageItem
}

export default function PackageCreateEditForm({
  onRefleshTable,
  currentUser,
  open,
  onClose,
}: Props) {
  const { enqueueSnackbar } = useSnackbar()

  const defaultValues = useMemo(
    () => ({
      ...currentUser,
      photo: `https://budget-routes.s3.sa-east-1.amazonaws.com/${currentUser?.photo}` || null,
      showInPdf: currentUser?.showInPdf || false,
      pricing: {
        ...currentUser?.pricing,
        children: currentUser?.pricing?.children?.length
          ? currentUser.pricing.children
          : Array(12).fill(0),
        adults: currentUser?.pricing?.adults?.length ? currentUser.pricing.adults : [0],
        seniors: currentUser?.pricing?.seniors?.length ? currentUser.pricing.seniors : [0],
      },
    }),
    [currentUser],
  )

  const methods = useForm<IPackageItem>({
    resolver: yupResolver(currentUser ? EditPackageSchema : NewPackageSchema),
    // @ts-ignore
    defaultValues,
  })

  const {
    reset,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = methods

  const onSubmit = useCallback(
    async (data: IPackageItem) => {
      try {
        let userId = currentUser?._id
        const photo = data?.photo

        delete data.photo
        if (currentUser) {
          await axiosInstance.put(`/packages/${currentUser._id}`, {
            ...data,
          })
        } else {
          const { data: response } = await axiosInstance.post('/packages', data)

          userId = response._id
        }

        if (typeof photo !== 'string' && photo) {
          const formDataUpload: FormData = new FormData()
          formDataUpload.append('file', photo)
          try {
            await axiosInstance.put(`/packages/${userId}/upload`, formDataUpload)
            enqueueSnackbar('Foto do pacote carregado !')
          } catch (error) {
            enqueueSnackbar('Erro ao enviar a foto!', {
              variant: 'error',
            })
          }
        }

        reset()
        onClose()
        onRefleshTable()
        enqueueSnackbar(
          currentUser ? 'Alteração realizada com sucesso!' : 'Pacote criada com sucesso!',
        )
      } catch (error) {
        if (error?.message) {
          enqueueSnackbar(error?.message, {
            variant: 'error',
          })
        } else {
          enqueueSnackbar('Erro na requisição!', {
            variant: 'error',
          })
        }
      }
    },
    [enqueueSnackbar, onClose, reset, currentUser, onRefleshTable],
  )

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0]

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      })

      if (file) {
        setValue('photo', newFile, { shouldValidate: true })
      }
    },
    [setValue],
  )

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      /*  onClose={onClose} */
      PaperProps={{
        sx: { maxWidth: 1024 },
      }}
    >
      <FormProvider methods={methods}>
        <DialogTitle>{currentUser ? 'Atualizar Pacote' : 'Cadastrar Pacote'}</DialogTitle>

        <DialogContent>
          <Box rowGap={2} columnGap={2} padding={1} display='grid'>
            <Grid item xs={12}>
              <RHFUploadAvatar
                name='photo'
                maxSize={10145728}
                onDrop={handleDrop}
                helperText={
                  <Typography
                    variant='caption'
                    sx={{
                      mt: 3,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.disabled',
                    }}
                  >
                    Permitido *.jpeg, *.jpg, *.png, *.gif
                    <br /> tamanho máximo de {fData(10145728)}
                  </Typography>
                }
              />

              <RHFTextField name='name' label='Nome' />
              <RHFEditor name='description' />
              <RHFTextField name='url' label='URL' />
              <RHFAutocomplete
                name='category'
                label='Categoria'
                options={[
                  { label: 'Combo', value: 'combo' },
                  { label: 'Atrativo', value: 'attractive' },
                  { label: 'Hospedagem', value: 'hosting' },
                  { label: 'Transporte', value: 'transfer' },
                ]}
              />
            </Grid>
            {/*      <Typography> Criterios </Typography> */}
            {/*  <Box rowGap={2} columnGap={2} padding={1} display='flex'>
              <RHFTextField name='rules.kid' label='Idade maxima de Infantil' type='number' />
              <RHFTextField name='rules.children' label='Idade maxima de criança' type='number' />
              <RHFTextField name='rules.adults' label='Idade maxima de Adulto' type='number' />

              <RHFTextField name='rules.seniors' label='Idade maxima de Idoso' type='number' />
            </Box> */}
            <Typography> Preços </Typography>
            <Box rowGap={2} columnGap={1} padding={1} display='flex' flexWrap='wrap'>
              {methods?.watch('pricing.children')?.map((_: any, index: number) => (
                <RHFTextField
                  key={`children-${index}`}
                  name={`pricing.children[${index}]`}
                  label={`Criança ${index + 1} anos`}
                  mask='currency'
                  sx={{ flexBasis: 'calc(25% - 8px)' }}
                />
              ))}

              <RHFTextField
                name='pricing.adults[0]'
                label='Adulto'
                mask='currency'
                required
                sx={{ flexBasis: 'calc(25% - 8px)' }}
              />
              <RHFTextField
                name='pricing.seniors[0]'
                label='Idoso'
                required
                mask='currency'
                sx={{ flexBasis: 'calc(25% - 8px)' }}
              />

              <RHFTextField
                name='pricing.pcd'
                label='PCD'
                mask='currency'
                sx={{ flexBasis: 'calc(25% - 8px)' }}
              />
              <RHFTextField
                name='pricing.student'
                label='Estudante(09 a 16)'
                mask='currency'
                sx={{ flexBasis: 'calc(25% - 8px)' }}
              />
              <RHFTextField
                name='pricing.studentAged'
                label='Estudante(16+)'
                mask='currency'
                sx={{ flexBasis: 'calc(25% - 8px)' }}
              />
              <RHFTextField
                name='pricing.resident'
                label='Morador'
                mask='currency'
                sx={{ flexBasis: 'calc(25% - 8px)' }}
              />
              <RHFTextField
                name='pricing.teacher'
                label='Professor(BR)'
                mask='currency'
                sx={{ flexBasis: 'calc(25% - 8px)' }}
              />
              <RHFTextField
                name='pricing.teacherPR'
                label='Professor(PR)'
                mask='currency'
                sx={{ flexBasis: 'calc(25% - 8px)' }}
              />
              <RHFTextField
                name='pricing.bloodDonor'
                label='Doador de sangue'
                mask='currency'
                sx={{ flexBasis: 'calc(25% - 8px)' }}
              />
              <RHFTextField
                name='pricing.idYoung'
                label='Id Jovem'
                mask='currency'
                sx={{ flexBasis: 'calc(25% - 8px)' }}
              />
              <RHFTextField
                name='pricing.military'
                label='Militar'
                mask='currency'
                sx={{ flexBasis: 'calc(25% - 8px)' }}
              />
            </Box>
            <RHFCheckbox name='showInPdf' label='Mostrar dado no PDF' />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant='outlined' onClick={onClose}>
            Cancelar
          </Button>

          <LoadingButton
            type='button'
            onClick={handleSubmit(onSubmit)}
            variant='contained'
            loading={isSubmitting}
          >
            {currentUser ? 'Atualizar' : 'Cadastrar'}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  )
}
