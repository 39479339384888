import yup from 'src/libs/yup'
import { masks } from 'src/utils/masks'

export const NewPackageSchema = yup.object().shape({
  name: yup.string().required('Nome é obrigatório'),
  description: yup.string().optional(),
  url: yup.string().url().optional(),
  category: yup.mixed().transform((value) => value.value),
  pricing: yup
    .object()
    .shape({
      children: yup.array(yup.mixed().transform((value) => +masks.unmask(value || 0))),

      resident: yup
        .mixed()
        .required('Preço para crianças é obrigatório')
        .transform((value) => +masks.unmask(value)),
      student: yup
        .mixed()
        .required('Preço para crianças é obrigatório')
        .transform((value) => +masks.unmask(value)),
      adults: yup.array(yup.mixed().transform((value) => +masks.unmask(value || 0))),
      seniors: yup.array(yup.mixed().transform((value) => +masks.unmask(value || 0))),
      pcd: yup
        .mixed()
        .required('Preço para PCD é obrigatório')
        .transform((value) => +masks.unmask(value)),
      studentAged: yup
        .mixed()
        .required('Preço para esutante 16+ é obrigatório')
        .transform((value) => +masks.unmask(value)),

      teacher: yup
        .mixed()
        .required('Preço para professor BR é obrigatório')
        .transform((value) => +masks.unmask(value)),
      teacherPR: yup
        .mixed()
        .required('Preço para professor PR é obrigatório')
        .transform((value) => +masks.unmask(value)),
      bloodDonor: yup
        .mixed()
        .required('Preço para doador é obrigatório')
        .transform((value) => +masks.unmask(value)),
      idYoung: yup
        .mixed()
        .required('Preço para id Jovem é obrigatório')
        .transform((value) => +masks.unmask(value)),
      military: yup
        .mixed()
        .required('Preço para militar é obrigatório')
        .transform((value) => +masks.unmask(value)),
    })
    .required('Preço é obrigatório'),
  /*   rules: yup
    .object()
    .shape({
      children: yup.number().required('Idade para crianças é obrigatório'),
      adults: yup.number().required('Idade para adultos é obrigatório'),
      seniors: yup.number().required('Idade para idosos é obrigatório'),
    })
    .required('Idade é obrigatório'), */
})

export const EditPackageSchema = yup.object().shape({
  name: yup.string().required('Nome é obrigatório'),
  description: yup.string().optional(),
  url: yup.string().url().optional(),
  category: yup.mixed().transform((value) => value.value),
  pricing: yup
    .object()
    .shape({
      children: yup.array(yup.mixed().transform((value) => +masks.unmask(value || 0))),

      resident: yup
        .mixed()
        .required('Preço para crianças é obrigatório')
        .transform((value) => +masks.unmask(value)),
      student: yup
        .mixed()
        .required('Preço para crianças é obrigatório')
        .transform((value) => +masks.unmask(value)),
      adults: yup.array(yup.mixed().transform((value) => +masks.unmask(value || 0))),
      seniors: yup.array(yup.mixed().transform((value) => +masks.unmask(value || 0))),
      pcd: yup
        .mixed()
        .required('Preço para PCD é obrigatório')
        .transform((value) => +masks.unmask(value)),
      studentAged: yup
        .mixed()
        .required('Preço para esutante 16+ é obrigatório')
        .transform((value) => +masks.unmask(value)),

      teacher: yup
        .mixed()
        .required('Preço para professor BR é obrigatório')
        .transform((value) => +masks.unmask(value)),
      teacherPR: yup
        .mixed()
        .required('Preço para professor PR é obrigatório')
        .transform((value) => +masks.unmask(value)),
      bloodDonor: yup
        .mixed()
        .required('Preço para doador é obrigatório')
        .transform((value) => +masks.unmask(value)),
      idYoung: yup
        .mixed()
        .required('Preço para id Jovem é obrigatório')
        .transform((value) => +masks.unmask(value)),
      military: yup
        .mixed()
        .required('Preço para militar é obrigatório')
        .transform((value) => +masks.unmask(value)),
    })
    .required('Preço é obrigatório'),
  /*   rules: yup
    .object()
    .shape({
      children: yup.number().required('Idade para crianças é obrigatório'),
      adults: yup.number().required('Idade para adultos é obrigatório'),
      seniors: yup.number().required('Idade para idosos é obrigatório'),
    })
    .required('Idade é obrigatório'), */
})
