export const tableHead: TableHead = {
  pcd: { id: 'pcd', label: 'PCD' },
  student: { id: 'student', label: 'Estudante' },
  resident: { id: 'resident', label: 'Residente' },
  studentAged: { id: 'studentAged', label: 'Estudante +16' },
  teacher: { id: 'teacher', label: 'Professor' },
  teacherPR: { id: 'teacherPR', label: 'Professor(PR)' },
  bloodDonor: { id: 'bloodDonor', label: 'Doador de Sangue' },
  idYoung: { id: 'idYoung', label: 'Identidade Jovem' },
  military: { id: 'military', label: 'Militar' },

  children0: { id: 'children0', label: 'Crianças 1' },
  children1: { id: 'children1', label: 'Crianças 2' },
  children2: { id: 'children2', label: 'Crianças 3' },
  children3: { id: 'children3', label: 'Crianças 4' },
  children4: { id: 'children4', label: 'Crianças 5' },
  children5: { id: 'children5', label: 'Crianças 6' },
  children6: { id: 'children6', label: 'Crianças 7' },
  children7: { id: 'children7', label: 'Crianças 8' },
  children8: { id: 'children8', label: 'Crianças 9' },
  children9: { id: 'children9', label: 'Crianças 10' },
  children10: { id: 'children10', label: 'Crianças 11' },
  children11: { id: 'children11', label: 'Crianças 12' },
  adults0: { id: 'adults0', label: 'Adultos' },
  seniors0: { id: 'seniors0', label: 'Idosos' },
}

export const TABLE_HEAD: TableColumn[] = [
  { id: 'name', label: 'Nome' },
  { id: 'observation', label: 'Observação' },
]
