// @ts-nocheck
import React, { useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Chip from '@mui/material/Chip'

function MyAutocomplete({ clients, setValue }: any) {
  const [selectedClients, setSelectedClients] = useState([])

  const handleSelect = (event, newValue, reason, option) => {
    const updatedClients = [...selectedClients, option.option]
    setSelectedClients(updatedClients)
    setValue(updatedClients)
  }

  const handleDelete = (itemToDelete) => {
    setSelectedClients((l) => l.filter((item, index) => index !== itemToDelete))
    setValue((l) => l.filter((item, index) => index !== itemToDelete))
  }

  return (
    <Autocomplete
      options={clients.data || []}
      multiple
      fullWidth
      getOptionLabel={(option) => option.label || ''}
      value={selectedClients}
      onChange={(event, newValue, reason, option) => {
        handleSelect(event, newValue, reason, option)
      }}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            key={index}
            label={option.label || ''}
            {...getTagProps({ index })}
            onDelete={(e) => handleDelete(index)}
          />
        ))
      }
      renderInput={(params) => (
        <TextField {...params} label='Selecione o cliente' placeholder='Selecione um cliente' />
      )}
    />
  )
}

export default MyAutocomplete
