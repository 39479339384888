// @ts-nocheck
export const getTotalPricingByType = (clients, tableHead) => {
  // Inicializa o objeto pricingByType com os tipos definidos no tableHead, todos com valor 0
  const pricingByType: {
    pcd: number
    student: number
    resident: number
    studentAged: number
    teacher: number
    teacherPR: number
    bloodDonor: number
    idYoung: number
    military: number
    kid: number
    children0: number
    children1: number
    children2: number
    children3: number
    children4: number
    children5: number
    children6: number
    children7: number
    children8: number
    children9: number
    children10: number
    children11: number
    adults0: number
    seniors0: number
  } = {}

  tableHead.forEach((item) => {
    if (item.id !== 'name' && item.id !== 'observation') {
      pricingByType[item.id] = 0
    }
  })

  // Popula os valores reais baseados nos clients
  clients.forEach(({ type, pricing }) => {
    if (pricingByType[type] !== undefined) {
      pricingByType[type] += pricing
    }
  })

  return pricingByType
}
