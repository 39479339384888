// eslint-disable

import { useQuery } from '@tanstack/react-query'
import { use } from 'i18next'
import { Pagination } from 'src/components/datagrid'
import { IPackageItem } from 'src/types/package'

import axiosInstance from 'src/utils/axios'

type Packages = {
  docs: IPackageItem[]
  page: number
  perPage: number
  totalPages: number
  totalDocs: number
}

type PackageAutoComplete = {
  value: string
  label: string
}

async function fetchAutoCompletePackages(): Promise<PackageAutoComplete[]> {
  const res = await axiosInstance.get('/packages/autocomplete')
  return res.data
}

async function fetchPackage(pagination: Pagination, filters: any): Promise<Packages> {
  const res = await axiosInstance.get('/packages', {
    params: {
      ...pagination,
      ...filters,
    },
  })
  return res.data
}

function usePackages(pagination: Pagination, filters?: any) {
  return useQuery(['packages', pagination, filters], () => fetchPackage(pagination, filters), {
    keepPreviousData: true,
    staleTime: 50000,
  })
}

export function usePackageAutoComplete() {
  return useQuery(['packagesAutoCOmplete'], fetchAutoCompletePackages, {
    keepPreviousData: true,
    staleTime: 50000,
  })
}

export default usePackages
