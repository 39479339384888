import { FormControl } from '@mui/material'
import { DateRangePicker } from '@mui/x-date-pickers-pro'
import { useCallback } from 'react'

export const SelectDateRange = ({ onFilters, filters }: { onFilters: any; filters: any }) => {
  const handleFilterService = useCallback(
    (event: any) => {
      if (event[0] && event[1]) {
        onFilters('dateRange', [event[0], event[1]])
      }
    },
    [onFilters],
  )

  return (
    <FormControl
      sx={{
        flexShrink: 0,
        width: '100%',
      }}
    >
      <DateRangePicker
        format='dd/MM/yyyy'
        defaultValue={filters.dateRange}
        localeText={{ start: 'Data inicial', end: 'Data final' }}
        onChange={handleFilterService}
      />
    </FormControl>
  )
}
