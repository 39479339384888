// @mui
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Stack, { StackProps } from '@mui/material/Stack'
// types
import { IBudgetTableFilters, IBudgetTableFilterValue } from 'src/types/budget'

// components
import Iconify from 'src/components/iconify'
import { format } from 'date-fns'

// ----------------------------------------------------------------------

type Props = StackProps & {
  filters: IBudgetTableFilters
  onFilters: (name: string, value: IBudgetTableFilterValue) => void
  //
  onResetFilters: VoidFunction
  //
  results: number
}

export default function UserTableFiltersResult({
  filters,
  onFilters,
  //
  onResetFilters,
  //
  results,
  ...other
}: Props) {
  /*   const handleRemoveSalers = (inputValue: string) => {
    const newValue = filters.sellers?.filter((item) => item !== inputValue) || []
    onFilters('sellers', newValue)
  } */

  return (
    <Stack spacing={1.5} {...other}>
      <Box sx={{ typography: 'body2' }}>
        <strong>{results}</strong>
        <Box component='span' sx={{ color: 'text.secondary', ml: 0.25 }}>
          resultados encontrados
        </Box>
      </Box>

      <Stack flexGrow={1} spacing={1} direction='row' flexWrap='wrap' alignItems='center'>
        {!!filters.sellers?.length && (
          <Block label='Vendedores:'>
            {filters.sellers.map((item) => (
              <Chip
                key={item?._id}
                label={item?.label}
                size='small' /* 
                onDelete={() => handleRemoveSalers(item)} */
              />
            ))}
          </Block>
        )}

        {!!filters.dateRange?.length && (
          <Block label='Datas:'>
            {filters.dateRange.map((item, index) => (
              <Chip
                key={index}
                label={format(item, 'dd/MM/yyyy')}
                size='small' /* 
                onDelete={() => handleRemoveSalers(item)} */
              />
            ))}
          </Block>
        )}

        {!!filters.search && (
          <Block label='Pesquisa:'>
            <Chip label={filters.search} size='small' />
          </Block>
        )}

        <Button
          color='error'
          onClick={onResetFilters}
          startIcon={<Iconify icon='solar:trash-bin-trash-bold' />}
        >
          Limpar
        </Button>
      </Stack>
    </Stack>
  )
}

// ----------------------------------------------------------------------

type BlockProps = StackProps & {
  label: string
}

function Block({ label, children, sx, ...other }: BlockProps) {
  return (
    <Stack
      component={Paper}
      variant='outlined'
      spacing={1}
      direction='row'
      sx={{
        p: 1,
        borderRadius: 1,
        overflow: 'hidden',
        borderStyle: 'dashed',
        ...sx,
      }}
      {...other}
    >
      <Box component='span' sx={{ typography: 'subtitle2' }}>
        {label}
      </Box>

      <Stack spacing={1} direction='row' flexWrap='wrap'>
        {children}
      </Stack>
    </Stack>
  )
}
