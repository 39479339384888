//
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import TableContainer from '@mui/material/TableContainer'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'

import Iconify from 'src/components/iconify'

import { useBoolean } from 'src/hooks/use-boolean'

import { ConfirmDialog } from '../custom-dialog'
import { DataGridProps } from './types'
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  TableSkeleton,
  emptyRows,
  useTable,
} from '../table'
import Scrollbar from '../scrollbar'

// ----------------------------------------------------------------------

export default function Datagrid({
  data,
  pagination,
  noSelection = false,
  setPagination,
  headLabel,
  notFound,
  ItemContent,
  total,
  isLoading,
  handleDeleteRows,
  handleDeleteRow,
  handleEditRow,
  refetch,
  disableFooter = false,
}: DataGridProps) {
  const table = useTable({
    defaultCurrentPage: 0,
    defaultRowsPerPage: 8,
    defaultOrder: 'desc',
    defaultOrderBy: 'createdAt',
  })
  const confirm = useBoolean()

  const denseHeight = table.dense ? 52 : 72

  return (
    <>
      <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
        <TableSelectedAction
          dense={table.dense}
          numSelected={table.selected.length}
          rowCount={data.length || 0}
          onSelectAllRows={(checked) =>
            data &&
            table.onSelectAllRows(
              checked,
              data?.map((row) => row._id),
            )
          }
          action={
            <Tooltip title='Deletar'>
              <IconButton color='primary' onClick={confirm.onTrue}>
                <Iconify icon='solar:trash-bin-trash-bold' />
              </IconButton>
            </Tooltip>
          }
        />
        <Scrollbar>
          <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
            <TableHeadCustom
              onRequestSort={(sortValue: string, orderValue: string) => {
                setPagination((prevState: any) => ({
                  ...prevState,
                  sort: { [sortValue]: orderValue },
                }))
              }}
              order={table.order}
              orderBy={table.orderBy}
              headLabel={headLabel}
              rowCount={data.length}
              numSelected={table.selected.length}
              onSort={table.onSort}
              onSelectAllRows={
                !noSelection
                  ? (checked) =>
                      !noSelection &&
                      data &&
                      table.onSelectAllRows(
                        checked,
                        data?.map((row) => row._id),
                      )
                  : undefined
              }
            />

            <TableBody>
              {isLoading ? (
                [...Array(table.rowsPerPage)].map((i, index) => (
                  <TableSkeleton key={index} sx={{ height: denseHeight }} />
                ))
              ) : (
                <>
                  {data.map((row) => (
                    <ItemContent
                      key={row._id}
                      row={row}
                      refetch={refetch}
                      selected={table.selected.includes(row._id)}
                      onSelectRow={() => table.onSelectRow(row._id)}
                      onDeleteRow={() => handleDeleteRow && handleDeleteRow(row._id)}
                      onEditRow={() => handleEditRow && handleEditRow(row._id)}
                    />
                  ))}
                </>
              )}

              <TableEmptyRows
                height={denseHeight}
                emptyRows={emptyRows(table.page, table.rowsPerPage, total)}
              />
              {!isLoading && <TableNoData notFound={notFound} />}
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>
      {!disableFooter && (
        <TablePaginationCustom
          count={total}
          page={table.page}
          rowsPerPage={pagination.perPage}
          onPageChange={(_, newPage) => {
            table.setPage(newPage)
            setPagination((prevState: any) => ({ ...prevState, page: newPage + 1 }))
          }}
          onRowsPerPageChange={(row) => {
            setPagination((prevState: any) => ({
              ...prevState,
              perPage: Number(row?.target?.value),
            }))
          }}
          dense={table.dense}
          onChangeDense={table.onChangeDense}
        />
      )}
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title='Deletar'
        content={
          <>
            Tem certeza de que deseja excluir <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant='contained'
            color='error'
            onClick={() => {
              if (handleDeleteRows) handleDeleteRows(table.selected)
              confirm.onFalse()
            }}
          >
            Deletar
          </Button>
        }
      />
    </>
  )
}
