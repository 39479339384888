import yup from 'src/libs/yup'

export const NewClientSchema = yup.object().shape({
  name: yup.string().required('Nome é obrigatório'),
  age: yup.number().required('Idade é obrigatória'),
  email: yup.string().email('O e-mail deve ser um endereço de e-mail válido').optional(),
  phone: yup.string(),
  pcd: yup.boolean(),
  student: yup.boolean(),
  resident: yup.boolean(),
  teacher: yup.boolean(),
  teacherPR: yup.boolean(),
  military: yup.boolean(),
  idYoung: yup.boolean(),
})

export const EditClientSchema = yup.object().shape({
  name: yup.string().required('Nome é obrigatório'),
  age: yup.number().required('Idade é obrigatória'),
  email: yup.string().email('O e-mail deve ser um endereço de e-mail válido').optional(),
  phone: yup.string(),
  pcd: yup.boolean(),
  student: yup.boolean(),
  resident: yup.boolean(),
  teacher: yup.boolean(),
  teacherPR: yup.boolean(),
  military: yup.boolean(),
  idYoung: yup.boolean(),
})
