import { useMemo } from 'react'
// routes
import { paths } from 'src/routes/paths'
// locales
// components
import Iconify from 'src/components/iconify'

// ----------------------------------------------------------------------

const icon = (name: string) => <Iconify icon={name} />

const ICONS = {
  user: icon('carbon:user-data'),
  dashboard: icon('carbon:dashboard'),
  products: icon('carbon:inventory-management'),
  reports: icon('carbon:report'),
  events: icon('carbon:calendar'),
  orders: icon('carbon:shopping-cart'),
}

// ----------------------------------------------------------------------

export function useNavData() {
  const data = useMemo(
    () => [
      {
        subheader: 'Dashboard',
        items: [
          /*  { title: 'Início', path: paths.dashboard.root, icon: ICONS.dashboard }, */
          { title: 'Usuários', path: paths.dashboard.user, icon: ICONS.user },
          { title: 'Clientes', path: paths.dashboard.clients, icon: ICONS.user },
          { title: 'Pacotes', path: paths.dashboard.packages, icon: ICONS.reports },
          { title: 'Orçamentos', path: paths.dashboard.budgets.root, icon: ICONS.reports },
        ],
      },
    ],
    [],
  )

  return data
}
