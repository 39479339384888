import { lazy } from 'react'
import { Outlet } from 'react-router-dom'
import { GuestGuard } from 'src/auth/guard'
import AuthLayout from 'src/layout/auth/authLayout'
import ForgotPasswordPage from 'src/pages/auth/forgot-password'
import NewPasswordPage from 'src/pages/auth/new-password'
import RegisterPage from 'src/pages/auth/register'
import VerifyPage from 'src/pages/auth/verify'
// layouts

// MODERN
const LoginPage = lazy(() => import('src/pages/auth/login'))

export const authRoutes = [
  {
    path: 'auth',
    element: (
      <GuestGuard>
        <Outlet />
      </GuestGuard>
    ),
    children: [
      {
        element: (
          <AuthLayout>
            <Outlet />
          </AuthLayout>
        ),
        children: [
          { path: 'login', element: <LoginPage /> },
          { path: 'register', element: <RegisterPage /> },
          { path: 'forgot-password', element: <ForgotPasswordPage /> },
          { path: 'new-password/:token', element: <NewPasswordPage /> },
          { path: 'verify', element: <VerifyPage /> },
        ],
      },
    ],
  },
]
