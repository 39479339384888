import React, { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, Container } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { useFieldArray, useForm } from 'react-hook-form'
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs'
import FormProvider, { RHFTextField } from 'src/components/hook-form'
import RHFAutocomplete from 'src/components/hook-form/rhf-autocomplete'
import { useSettingsContext } from 'src/components/settings'
import { paths } from 'src/routes/paths'
import { useClientAutoComplete } from 'src/service/clients.service'
import { usePackageAutoComplete } from 'src/service/packages.service'
import Button from '@mui/material/Button'
import Iconify from 'src/components/iconify'
import { useBoolean } from 'src/hooks/use-boolean'
import { enqueueSnackbar } from 'notistack'
import axiosInstance from 'src/utils/axios'
import { NewBudgetSchema } from 'src/validators/budget.schemas'
import { useRouter } from 'src/routes/hook'
import Notiflix from 'notiflix'

import RHFCalendar from 'src/components/hook-form/rhf-calendar'

import MyAutocomplete from 'src/components/hook-form/autocomplete-cliente'
import { addDays } from 'date-fns'
import ClientCreateEditForm from '../../clients/client-create-edit-view'

export default function CreateBudget() {
  const settings = useSettingsContext()
  const packages = usePackageAutoComplete()
  const clients = useClientAutoComplete()
  const methods = useForm({
    resolver: yupResolver(NewBudgetSchema),
  })
  const [selectedClients, setSelectedClients] = useState([])

  const quickEdit = useBoolean()

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'packages',
  })

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods
  const router = useRouter()

  const onSubmit = async (data: any) => {
    const { Loading } = Notiflix
    try {
      Loading.arrows('Criando orçamento...')
      // @ts-ignore
      data.clients = selectedClients.map((client) => client.value)
      const response = await axiosInstance.post('/budgets', data)

      enqueueSnackbar('Orçamento criado com sucesso!', { variant: 'success' })
      reset()
      router.push(paths.dashboard.budgets.preview(response.data._id))
      Loading.remove()
    } catch (error) {
      Loading.remove()
      if (error?.message) {
        enqueueSnackbar(error?.message, { variant: 'error' })
      } else {
        enqueueSnackbar('Erro na requisição!', { variant: 'error' })
      }
    }
  }

  // @ts-ignore
  const handleSetValue = (newValues) => {
    setSelectedClients(newValues) // Atualiza o estado com os clientes selecionados
  }

  const returnDateFromPreviues = () => {
    const data = methods.getValues('packages').map((item: any) => item.name)

    if (!data.length) {
      return new Date()
    }

    return new Date(addDays(new Date(data[data.length - 1]), 1))
  }

  return (
    <>
      <Helmet>
        <title>Orçamentos - Novo</title>
      </Helmet>

      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading='Novo orçamento'
          links={[
            { name: 'Orçamentos', href: paths.dashboard.budgets.root },
            { name: 'Novo orçamento' },
          ]}
          sx={{ mb: { xs: 3, md: 5 } }}
        />

        <Card>
          <FormProvider methods={methods}>
            <Box rowGap={2} columnGap={2} padding={1} display='grid'>
              <RHFTextField name='name' label='Nome do orçamento' />
              <Box rowGap={2} columnGap={2} padding={1} display='flex'>
                <MyAutocomplete clients={clients || []} setValue={handleSetValue} />

                {/*  <RHFAutocomplete
                  name='clients'
                  label='Clientes'
                  freeSolo
                  options={clients.data || []}
                  multiple
                  sx={{ flexBasis: 'calc(80% - 8px)' }}
                /> */}
                <Button
                  onClick={() => quickEdit.onTrue()}
                  variant='contained'
                  sx={{ flexBasis: 'calc(20% - 8px)' }}
                >
                  Incluir cliente
                </Button>
              </Box>
              <Button
                onClick={() => append({ name: returnDateFromPreviues(), package: [] })}
                variant='contained'
              >
                Incluir data
              </Button>

              <Box rowGap={2} columnGap={2} padding={1} display='grid'>
                {fields.map((field, index) => (
                  <div key={field.id}>
                    <Accordion expanded>
                      <AccordionSummary>
                        {/* <RHFTextField name={`packages.${index}.name`} label='Data do passeio' /> */}
                        <RHFCalendar name={`packages.${index}.name`} label='Data do passeio' />
                        <Iconify
                          icon='mingcute:delete-2-line'
                          onClick={() => remove(index)}
                          width={35}
                          sx={{ cursor: 'pointer', color: 'red' }}
                        />
                      </AccordionSummary>
                      <AccordionDetails>
                        <RHFAutocomplete
                          name={`packages.${index}.package`}
                          label='Pacotes'
                          isOptionEqualToValue={(option, value) => option.value === value.value}
                          options={packages.data || []}
                          multiple
                        />
                      </AccordionDetails>
                    </Accordion>
                  </div>
                ))}
              </Box>
              <Button
                onClick={handleSubmit(onSubmit)}
                variant='contained'
                type='button'
                disabled={isSubmitting}
              >
                Ir para pré visualização
              </Button>
            </Box>
          </FormProvider>
        </Card>
      </Container>
      <ClientCreateEditForm
        onRefleshTable={() => clients.refetch()}
        open={quickEdit.value}
        onClose={quickEdit.onFalse}
      />
    </>
  )
}
