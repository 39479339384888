import { useFormContext, Controller } from 'react-hook-form'
// @mui
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { masks } from 'src/utils/masks'

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string
  mask?: string
}

export default function RHFTextField({ name, mask, helperText, type, ...other }: Props) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        // Aplicamos a máscara aqui ao valor inicial
        let maskedValue = field.value

        if (mask) {
          maskedValue = masks[mask](maskedValue)
        }

        return (
          <TextField
            {...field}
            fullWidth
            type={type}
            value={type === 'number' && field.value === 0 ? '' : maskedValue}
            onChange={(event) => {
              let { value } = event.target

              if (mask) {
                const rawValue = value.replace(/\D/g, '') // Remove todos os não dígitos
                value = masks[mask](rawValue) // Aplica a máscara no valor bruto
              }

              if (type === 'number') {
                field.onChange(Number(value))
              } else {
                field.onChange(value)
              }
            }}
            error={!!error}
            helperText={error ? error?.message : helperText}
            {...other}
          />
        )
      }}
    />
  )
}
