import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { useSnackbar } from 'src/components/snackbar'
import FormProvider, { RHFTextField } from 'src/components/hook-form'
import axiosInstance from 'src/utils/axios'
import { IUserItem } from 'src/types/user'
import { EditClientSchema, NewClientSchema } from 'src/validators/client.schemas'
import { RHFCheckbox } from 'src/components/hook-form/rhf-checkbox'

type Props = {
  open: boolean
  onClose: VoidFunction
  onRefleshTable: VoidFunction
  currentUser?: IUserItem
}

export default function ClientCreateEditForm({
  onRefleshTable,
  currentUser,
  open,
  onClose,
}: Props) {
  const { enqueueSnackbar } = useSnackbar()

  const defaultValues = useMemo(
    () => ({
      ...currentUser,
      name: currentUser?.name || '',
      active: currentUser?.active,
      phone: currentUser?.phone || '',
      email: currentUser?.email || '',
      age: currentUser?.age || 0,
      resident: currentUser?.resident || false,
      student: currentUser?.student || false,
      pcd: currentUser?.pcd || false,
      agesType:
        Object.values(currentUser?.agesType || {}).length !== 0
          ? currentUser?.agesType
          : {
              children: Array(12).fill(false),
              adults: [false],
              seniors: [false],
            },
    }),
    [currentUser],
  )

  const methods = useForm<IUserItem>({
    resolver: yupResolver(currentUser ? EditClientSchema : NewClientSchema),
    defaultValues,
  })

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const onSubmit = useCallback(
    async (data: IUserItem) => {
      try {
        if (currentUser) {
          await axiosInstance.put(`/clients/${currentUser._id}`, {
            ...data,
            photo: undefined,
            email: undefined,
            password: undefined,
          })
        } else {
          await axiosInstance.post('/clients', data)
        }

        reset()
        onClose()
        onRefleshTable()
        enqueueSnackbar(
          currentUser ? 'Alteração realizada com sucesso!' : 'Cliente criada com sucesso!',
        )
      } catch (error) {
        enqueueSnackbar(error?.message || 'Erro na requisição!', { variant: 'error' })
      }
    },
    [enqueueSnackbar, onClose, reset, currentUser, onRefleshTable],
  )

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods}>
        <DialogTitle>{currentUser ? 'Atualizar Cliente' : 'Cadastrar Cliente'}</DialogTitle>

        <DialogContent>
          {currentUser?._id}
          <Box rowGap={2} columnGap={2} padding={1} display='grid'>
            <RHFTextField name='name' label='Nome' />
            <RHFTextField name='email' label='Email' />
            <RHFTextField name='phone' label='Telefone' placeholder='+5545984064885' />
            {/*  <RHFTextField name='age' label='Idade' type='number' /> */}
          </Box>

          <Box rowGap={2} columnGap={1} padding={1} display='flex' flexWrap='wrap'>
            {/* Existing Checkboxes */}
            <RHFCheckbox
              name='agesType.adults[0]'
              label='Adulto'
              sx={{ flexBasis: 'calc(25% - 8px)' }}
            />
            <RHFCheckbox
              name='agesType.seniors[0]'
              label='Idoso'
              sx={{ flexBasis: 'calc(25% - 8px)' }}
            />
            <RHFCheckbox name='resident' label='Morador' sx={{ flexBasis: 'calc(25% - 8px)' }} />
            <RHFCheckbox name='student' label='Estudante' sx={{ flexBasis: 'calc(25% - 8px)' }} />
            <RHFCheckbox name='pcd' label='PCD' sx={{ flexBasis: 'calc(25% - 8px)' }} />
            <RHFCheckbox name='idYoung' label='Id Jovem' sx={{ flexBasis: 'calc(25% - 8px)' }} />
            <RHFCheckbox name='military' label='Militar' sx={{ flexBasis: 'calc(25% - 8px)' }} />
            <RHFCheckbox name='bloodDonor' label='Doador' sx={{ flexBasis: 'calc(25% - 8px)' }} />
            <RHFCheckbox
              name='teacher'
              label='Professor(BR)'
              sx={{ flexBasis: 'calc(25% - 8px)' }}
            />
            <RHFCheckbox
              name='teacherPR'
              label='Professor(PR)'
              sx={{ flexBasis: 'calc(25% - 8px)' }}
            />

            {methods?.watch('agesType.children')?.map((_: any, index: number) => (
              <RHFCheckbox
                key={`children-${index}`}
                name={`agesType.children[${index}]`}
                label={`Criança ${index + 1}`}
                sx={{ flexBasis: 'calc(25% - 8px)' }}
              />
            ))}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant='outlined' onClick={onClose}>
            Cancelar
          </Button>

          <LoadingButton
            type='button'
            onClick={handleSubmit(onSubmit)}
            variant='contained'
            loading={isSubmitting}
          >
            {currentUser ? 'Atualizar' : 'Cadastrar'}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  )
}
