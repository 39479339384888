import isEqual from 'lodash/isEqual'
import { useState, useCallback } from 'react'
// @mui
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'

// routes
import { paths } from 'src/routes/paths'

import { RouterLink } from 'src/routes/components'

// components
import Iconify from 'src/components/iconify'
import { useSettingsContext } from 'src/components/settings'
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs'

import Datagrid from 'src/components/datagrid'

//
import { Helmet } from 'react-helmet-async'

import axiosInstance from 'src/utils/axios'
import { useSnackbar } from 'notistack'
import useBudgets from 'src/service/budgets.service'
import { IBudgetTableFilterValue } from 'src/types/budget'
import UserTableRow from './table-user/user-table-row'
import UserTableToolbar from './table-user/user-table-toolbar'
import UserTableFiltersResult from './table-user/user-table-filters-result'

const _roles = [
  { value: 'admin', label: 'Admin' },
  { value: 'user', label: 'Usuario' },
]

const TABLE_HEAD = [
  { id: 'name', label: 'Nome' },
  { id: 'pdfUrl', label: 'Baixar PDF' },
  { id: 'status', label: 'Status', width: 100 },
  { id: 'seller', label: 'Vendedor', width: 180 },
  { id: 'createdAt', label: 'Data de criação', width: 180 },
  { id: '', width: 88 },
]

const defaultPagination = {
  page: 1,
  perPage: 8,
  sort: {
    createdAt: 'desc',
  },
}
const defaultFilters = {
  search: '',
  sellers: [],
  dateRange: [],
}
export default function BudgetsListView() {
  const [pagination, setPagination] = useState(defaultPagination)
  const [filters, setFilters] = useState(defaultFilters)

  const { isLoading, isFetching, data, refetch } = useBudgets(pagination, filters)

  const settings = useSettingsContext()

  const { enqueueSnackbar } = useSnackbar()

  const canReset = !isEqual(defaultFilters, filters)

  const notFound = (!data?.docs.length && canReset) || !data?.docs.length

  const handleFilters = useCallback((name: string, value: IBudgetTableFilterValue) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }, [])

  const handleDeleteRow = useCallback(
    async (id: string) => {
      try {
        await axiosInstance.delete(`/budgets/${id}`)

        enqueueSnackbar('Pacote removido com sucesso !')
        refetch()
      } catch (error) {
        if (error?.message) {
          enqueueSnackbar(error?.message, {
            variant: 'error',
          })
        } else {
          enqueueSnackbar('Erro ao remover orcamento !', {
            variant: 'error',
          })
        }
      }
    },
    [enqueueSnackbar, refetch],
  )

  const handleDeleteRows = useCallback(
    async (selectedValues: string[]) => {
      try {
        selectedValues.map(async (id) => {
          await axiosInstance.delete(`/budgets/${id}`)
        })

        enqueueSnackbar('Orçamentos removido com sucesso !')
        refetch()
      } catch (error) {
        if (error?.message) {
          enqueueSnackbar(error?.message, {
            variant: 'error',
          })
        } else {
          enqueueSnackbar('Erro ao remover pacote !', {
            variant: 'error',
          })
        }
      }
    },
    [enqueueSnackbar, refetch],
  )

  const handleEditRow = useCallback((id: string) => {
    // router.push(paths.dashboard.user.edit(id))
  }, [])

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters)
  }, [])

  return (
    <>
      <Helmet>
        <title>Orçamentos - Listagem</title>
      </Helmet>

      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading='Orçamentos'
          links={[{ name: 'Início', href: paths.dashboard.root }, { name: 'Orçamentos' }]}
          action={
            <Button
              component={RouterLink}
              href={paths.dashboard.budgets.create}
              variant='contained'
              startIcon={<Iconify icon='mingcute:add-line' />}
            >
              Criar um orçamento
            </Button>
          }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <Card>
          <UserTableToolbar filters={filters} onFilters={handleFilters} roleOptions={_roles} />

          {canReset && (
            <UserTableFiltersResult
              filters={filters}
              onFilters={handleFilters}
              onResetFilters={handleResetFilters}
              results={data?.docs.length || 0}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}

          <Datagrid
            data={data?.docs || []}
            notFound={notFound}
            isLoading={isLoading || isFetching}
            headLabel={TABLE_HEAD}
            refetch={refetch}
            total={data?.totalDocs || 0}
            setPagination={setPagination}
            pagination={pagination}
            handleDeleteRows={handleDeleteRows}
            ItemContent={UserTableRow}
            handleDeleteRow={handleDeleteRow}
            handleEditRow={handleEditRow}
          />
        </Card>
      </Container>
    </>
  )
}
