export function download(url: string, name?: string) {
  const downloadLink = document.createElement('a')

  // @ts-ignore
  downloadLink.href = `https://budget-routes.s3.sa-east-1.amazonaws.com/${url}`
  downloadLink.download = name || 'Orcamento.pdf'
  downloadLink.click()

  return true
}
