// @mui
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
//
import { ConfirmDialogProps } from './types'

// ----------------------------------------------------------------------

export default function ClientDeleteBudget({
  title,
  content,
  action,
  open,
  onClose,
  ...other
}: ConfirmDialogProps) {
  return (
    <Dialog fullWidth maxWidth='xs' open={open} onClose={onClose} {...other}>
      <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>

      <DialogContent sx={{ typography: 'body2' }}>
        {' '}
        Deletar cliente para todos os passeios?{' '}
      </DialogContent>

      <DialogActions>
        {action}

        <Button variant='outlined' color='inherit' onClick={onClose}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
