/* eslint-disable import/no-duplicates */
import React, { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'

// @mui
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'

import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'

// types
// components
import { useSnackbar } from 'src/components/snackbar'
import FormProvider, { RHFTextField } from 'src/components/hook-form'
import axiosInstance from 'src/utils/axios'
import { Grid } from '@mui/material'

type Props = {
  open: boolean
  onClose: VoidFunction
  onRefleshTable: VoidFunction
  currentUser?: { _id: string; name?: string }
}

export default function EditBudget({ onRefleshTable, currentUser, open, onClose }: Props) {
  const { enqueueSnackbar } = useSnackbar()

  const defaultValues = useMemo(
    () => ({
      ...currentUser,
    }),
    [currentUser],
  )

  const methods = useForm<any>({ defaultValues })

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const onSubmit = useCallback(
    async (data: any) => {
      try {
        await axiosInstance.put(`/budgets/${currentUser?._id}`, {
          name: data.name,
        })

        reset()
        onClose()
        onRefleshTable()
        enqueueSnackbar('Alteração realizada com sucesso!')
      } catch (error) {
        if (error?.message) {
          enqueueSnackbar(error?.message, {
            variant: 'error',
          })
        } else {
          enqueueSnackbar('Erro na requisição!', {
            variant: 'error',
          })
        }
      }
    },
    [enqueueSnackbar, onClose, reset, currentUser, onRefleshTable],
  )

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods}>
        <DialogTitle>Editar orçamento</DialogTitle>

        <DialogContent>
          <Box padding={1}>
            <Grid item xs={6} spacing={1}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <RHFTextField name='name' label='Nome' />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant='outlined' onClick={onClose} color='error'>
            Cancelar
          </Button>

          <LoadingButton
            type='button'
            onClick={handleSubmit(onSubmit)}
            variant='contained'
            loading={isSubmitting}
          >
            Adicionar
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  )
}
