import { m } from 'framer-motion'
// @mui
import { Theme, SxProps } from '@mui/material/styles'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
// hooks
//
// components
import { MotionContainer, varBounce } from 'src/components/animate'
import { useUserStore } from 'src/store/user'
import { useEffect } from 'react'
import { Button } from '@mui/material'
import { useAuthContext } from '../hooks'

// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  hasContent?: boolean
  roles?: string[]
  children: React.ReactNode
  sx?: SxProps<Theme>
}

export default function RoleBasedGuard({ hasContent, roles, children, sx }: RoleBasedGuardProp) {
  // Logic here to get current user role
  const { user } = useUserStore()
  const { logout } = useAuthContext()

  // const currentRole = 'user';
  const currentRole = user?.role // admin;

  if (currentRole && typeof roles !== 'undefined' && !roles.includes(currentRole)) {
    return hasContent ? (
      <Container component={MotionContainer} sx={{ textAlign: 'center', ...sx }}>
        <m.div variants={varBounce().in}>
          <Typography variant='h3' paragraph>
            Permissão negada
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            Você não tem permissão para acessar esta página
          </Typography>
        </m.div>

        <Button
          variant='contained'
          onClick={() => {
            window.location.href = '/auth/login'
            logout()
          }}
          sx={{ mt: 5 }}
        >
          Sair
        </Button>
      </Container>
    ) : null
  }

  return <> {children} </>
}
