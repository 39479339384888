import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'

// @mui
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'

import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'

// types
// components
import { useSnackbar } from 'src/components/snackbar'
import FormProvider from 'src/components/hook-form'
import axiosInstance from 'src/utils/axios'
import RHFAutocomplete from 'src/components/hook-form/rhf-autocomplete'
import { Grid } from '@mui/material'

import { usePackageAutoComplete } from 'src/service/packages.service'

type Props = {
  open: boolean
  onClose: VoidFunction
  onRefleshTable: VoidFunction
  currentUser?: { name: string; _id: string }
}

export default function AddPackage({ onRefleshTable, currentUser, open, onClose }: Props) {
  const { enqueueSnackbar } = useSnackbar()
  const packages = usePackageAutoComplete()

  const methods = useForm<any>({
    /*     resolver: yupResolver(EditClientPackageSchema), */
  })

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const onSubmit = useCallback(
    async (data: any) => {
      try {
        await axiosInstance.put(
          `/budgets/${currentUser?._id}/package/${data?.packageId.value}/package`,
          {
            packageName: currentUser?.name,
          },
        )

        reset()
        onClose()
        onRefleshTable()
        enqueueSnackbar(
          currentUser ? 'Alteração realizada com sucesso!' : 'Cliente criada com sucesso!',
        )
      } catch (error) {
        if (error?.message) {
          enqueueSnackbar(error?.message, {
            variant: 'error',
          })
        } else {
          enqueueSnackbar('Erro na requisição!', {
            variant: 'error',
          })
        }
      }
    },
    [enqueueSnackbar, onClose, reset, currentUser, onRefleshTable],
  )

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods}>
        <DialogTitle>Adicionar passeio</DialogTitle>

        <DialogContent>
          <Box padding={1}>
            <Grid item xs={6} spacing={1}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <RHFAutocomplete
                    name='packageId'
                    label='Pacotes'
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    options={packages.data || []}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant='outlined' onClick={onClose} color='error'>
            Cancelar
          </Button>

          <LoadingButton
            type='button'
            onClick={handleSubmit(onSubmit)}
            variant='contained'
            loading={isSubmitting}
          >
            Adicionar
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  )
}
