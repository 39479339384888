import * as Yup from 'yup'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
// @mui
import LoadingButton from '@mui/lab/LoadingButton'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import InputAdornment from '@mui/material/InputAdornment'
// hooks
import { useBoolean } from 'src/hooks/use-boolean'
// routes
import { paths } from 'src/routes/paths'
// components
import Iconify from 'src/components/iconify'
import { RouterLink } from 'src/routes/components'
import FormProvider, { RHFTextField } from 'src/components/hook-form'
import { Helmet } from 'react-helmet-async'
import { Alert } from '@mui/material'
import { useAuthContext } from 'src/auth/hooks'
import { useAuthStore } from 'src/store/auth'

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string
  password: string
  name: string
}

export default function RegisterPage() {
  const { register } = useAuthContext()

  const [errorMsg, setErrorMsg] = React.useState('')

  const password = useBoolean()

  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    email: Yup.string()
      .required('Email é obrigatório')
      .email('O email deve ser um endereço de email válido'),
    password: Yup.string().required('Senha é obrigatória'),
  })
  const defaultValues = {
    name: '',
    email: '',
    password: '',
  }

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  })

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const onSubmit = useCallback(
    async (data: FormValuesProps) => {
      try {
        await register?.(data.email, data.password, data.name)
      } catch (error) {
        useAuthStore.getState().setLoading(false)
        reset()
        setErrorMsg(typeof error === 'string' ? error : error.message)
      }
    },
    [register, reset],
  )

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 3, position: 'relative' }}>
      <Typography variant='h4'>Registrar-se</Typography>

      <Stack direction='row' spacing={0.5} justifyContent='center'>
        <Typography variant='body2'>Já tem uma conta? </Typography>

        <Link href={paths.auth.login} component={RouterLink} variant='subtitle2'>
          Entrar
        </Link>
      </Stack>
    </Stack>
  )

  const renderTerms = (
    <Typography
      component='div'
      sx={{ color: 'text.secondary', mt: 2.5, typography: 'caption', textAlign: 'center' }}
    >
      {'Ao me registrar, eu concordo com '}
      <Link underline='always' color='text.primary'>
        Termos de Serviço
      </Link>
      {' e '}
      <Link underline='always' color='text.primary'>
        Política de Privacidade
      </Link>
      .
    </Typography>
  )

  const renderForm = (
    <Stack spacing={2.5}>
      {!!errorMsg && <Alert severity='error'>{errorMsg}</Alert>}

      <RHFTextField name='name' label='Nome' />

      <RHFTextField name='email' autoComplete='username' label='Email' />

      <RHFTextField
        name='password'
        label='Senha'
        autoComplete='new-password'
        type={password.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton onClick={password.onToggle} edge='end'>
                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <LoadingButton
        fullWidth
        color='inherit'
        size='large'
        type='submit'
        variant='contained'
        loading={isSubmitting}
        endIcon={<Iconify icon='eva:arrow-ios-forward-fill' />}
        sx={{ justifyContent: 'space-between', pl: 2, pr: 1.5 }}
      >
        Criar conta
      </LoadingButton>
    </Stack>
  )

  return (
    <>
      <Helmet>
        <title>Registro</title>
      </Helmet>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        {renderHead}

        {renderForm}

        {renderTerms}
      </FormProvider>
    </>
  )
}
