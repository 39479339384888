// @mui
import Button from '@mui/material/Button'
import Avatar from '@mui/material/Avatar'
import TableRow from '@mui/material/TableRow'
import Checkbox from '@mui/material/Checkbox'
import TableCell from '@mui/material/TableCell'
import IconButton from '@mui/material/IconButton'
import ListItemText from '@mui/material/ListItemText'
import Tooltip from '@mui/material/Tooltip'
// hooks
import { useBoolean } from 'src/hooks/use-boolean'
// types
import { IUserItem } from 'src/types/user'
// components
import Label from 'src/components/label'
import Iconify from 'src/components/iconify'
import { ConfirmDialog } from 'src/components/custom-dialog'
import { format } from 'date-fns'
import { masks } from 'src/utils/masks'
import UserCreateEditForm from '../user-create-edit-view'
//

// ----------------------------------------------------------------------

export const roleName = {
  admin: 'Administrador',
  user: 'Usuário',
  franchisee: 'Franqueado',
}

type Props = {
  selected: boolean
  onEditRow: VoidFunction
  row: IUserItem
  refetch: VoidFunction
  onSelectRow: VoidFunction
  onDeleteRow: VoidFunction
}

export default function UserTableRow({
  row,
  selected,
  refetch,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}: Props) {
  const { name, phone, email, active, createdAt } = row

  const confirm = useBoolean()
  const quickEdit = useBoolean()

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell padding='checkbox'>
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>

        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt={name} src='null' sx={{ mr: 2 }} />

          <ListItemText
            primary={name}
            secondary={email}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
          />
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{masks.phone(phone)}</TableCell>

        <TableCell>
          <Label
            variant='soft'
            color={(active && 'success') || (!active && 'warning') || 'default'}
          >
            {active ? 'Ativo' : 'Inativo'}
          </Label>
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {format(new Date(createdAt), 'dd/MM/yyyy HH:mm')}
        </TableCell>

        <TableCell align='right' sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Tooltip title='Editar' placement='top' arrow>
            <IconButton color={quickEdit.value ? 'inherit' : 'default'} onClick={quickEdit.onTrue}>
              <Iconify icon='solar:pen-bold' />
            </IconButton>
          </Tooltip>
          <Tooltip title='Deletar' placement='top' arrow>
            <IconButton
              onClick={() => {
                confirm.onTrue()
              }}
            >
              <Iconify color='red' icon='solar:trash-bin-trash-bold' />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>

      <UserCreateEditForm
        currentUser={row}
        onRefleshTable={() => refetch()}
        open={quickEdit.value}
        onClose={quickEdit.onFalse}
      />

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title='Delete'
        content='Tem certeza que quer deletar?'
        action={
          <Button variant='contained' color='error' onClick={onDeleteRow}>
            Deletar
          </Button>
        }
      />
    </>
  )
}
