import { useFormContext, Controller } from 'react-hook-form'
// @mui
import { TextFieldProps } from '@mui/material/TextField'
import { DatePicker } from '@mui/x-date-pickers'

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string
  mask?: string
}

export default function RHFCalendar({ name, mask, helperText, label, type, ...other }: Props) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <DatePicker
          format='dd/MM/yyyy'
          label={label}
          slotProps={{ textField: { fullWidth: true } }}
          value={field.value}
          inputRef={field.ref}
          onChange={(date) => {
            field.onChange(date)
          }}
        />
      )}
    />
  )
}
