import { lazy } from 'react'
import { Outlet } from 'react-router-dom'
import AuthLayout from 'src/layout/auth/authLayout'
// layouts

// ----------------------------------------------------------------------

const Page500 = lazy(() => import('src/pages/500'))
const Page403 = lazy(() => import('src/pages/403'))
const Page404 = lazy(() => import('src/pages/404'))
const ComingSoonPage = lazy(() => import('src/pages/coming-soon'))
const MaintenancePage = lazy(() => import('src/pages/maintenance'))

// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    element: (
      <AuthLayout>
        <Outlet />
      </AuthLayout>
    ),
    children: [
      { path: 'coming-soon', element: <ComingSoonPage /> },
      { path: 'maintenance', element: <MaintenancePage /> },
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },
    ],
  },
]
