import { Navigate, useRoutes, Outlet } from 'react-router-dom'

import { mainRoutes } from './main'
import { authRoutes } from './auth'
import { dashboardRoutes } from './dashboard'
//
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Main routes
    { path: '/', element: <Navigate to='/auth/login' replace /> },

    ...authRoutes,
    ...mainRoutes,

    ...dashboardRoutes,

    // No match 404
    { path: '*', element: <Navigate to='/404' replace /> },
  ])
}
