import * as Yup from 'yup'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
// @mui
import LoadingButton from '@mui/lab/LoadingButton'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
// routes
import { paths } from 'src/routes/paths'
import { RouterLink } from 'src/routes/components'
// components
import Iconify from 'src/components/iconify'
import FormProvider, { RHFTextField } from 'src/components/hook-form'
import { Helmet } from 'react-helmet-async'
import axiosInstance, { API_ENDPOINTS } from 'src/utils/axios'
import { useNavigate } from 'react-router'
import { useSnackbar } from 'notistack'

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string
}

export default function ForgotPasswordPage() {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email é obrigatório')
      .email('O email deve ser um endereço de email válido'),
  })

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(ResetPasswordSchema),
    defaultValues: {
      email: '',
    },
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const onSubmit = useCallback(
    async (data: FormValuesProps) => {
      try {
        await axiosInstance.post(API_ENDPOINTS.auth.forgot, data)

        enqueueSnackbar('Email enviado com sucesso !')

        navigate(`auth/${API_ENDPOINTS.auth.login}`)
      } catch (error) {
        if (error?.message) {
          enqueueSnackbar(error?.message, {
            variant: 'error',
          })
        } else {
          enqueueSnackbar('Erro ao enviar email !', {
            variant: 'error',
          })
        }
        console.error(error)
      }
    },
    [enqueueSnackbar, navigate],
  )

  const renderForm = (
    <Stack spacing={3} alignItems='center'>
      <RHFTextField name='email' label='Email address' />

      <LoadingButton
        fullWidth
        size='large'
        type='submit'
        variant='contained'
        loading={isSubmitting}
        endIcon={<Iconify icon='eva:arrow-ios-forward-fill' />}
        sx={{ justifyContent: 'space-between', pl: 2, pr: 1.5 }}
      >
        Enviar Solicitação
      </LoadingButton>

      <Link
        component={RouterLink}
        href={paths.auth.login}
        color='inherit'
        variant='subtitle2'
        sx={{
          alignItems: 'center',
          display: 'inline-flex',
        }}
      >
        <Iconify icon='eva:arrow-ios-back-fill' width={16} />
        Retornar para entrar
      </Link>
    </Stack>
  )

  const renderHead = (
    <Stack spacing={1} sx={{ mb: 5 }}>
      <Typography variant='h3'>Esqueceu sua senha?</Typography>

      <Typography variant='body2' sx={{ color: 'text.secondary' }}>
        Por favor, insira o endereço de email associado à sua conta e nós enviaremos um link para
        redefinir sua senha.
      </Typography>
    </Stack>
  )

  return (
    <>
      <Helmet>
        <title>Esqueci a senha</title>
      </Helmet>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        {renderHead}

        {renderForm}
      </FormProvider>
    </>
  )
}
