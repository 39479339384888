import * as Yup from 'yup'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
// @mui
import LoadingButton from '@mui/lab/LoadingButton'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
// rotas
import { paths } from 'src/routes/paths'
import { RouterLink } from 'src/routes/components'
// componentes
import Iconify from 'src/components/iconify'
import FormProvider, { RHFCode, RHFTextField } from 'src/components/hook-form'
import { Helmet } from 'react-helmet-async'

// ----------------------------------------------------------------------

type FormValuesProps = {
  code: string
  email: string
}

export default function VerifyPage() {
  const VerifySchema = Yup.object().shape({
    code: Yup.string()
      .min(6, 'Código deve ter pelo menos 6 caracteres')
      .required('Código é obrigatório'),
    email: Yup.string()
      .required('Email é obrigatório')
      .email('Email deve ser um endereço de email válido'),
  })

  const defaultValues = {
    code: '',
    email: '',
  }

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(VerifySchema),
    defaultValues,
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const onSubmit = useCallback(async (data: FormValuesProps) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500))
      console.info('DADOS', data)
    } catch (error) {
      console.error(error)
    }
  }, [])

  const renderForm = (
    <Stack spacing={3} alignItems='center'>
      <RHFTextField
        name='email'
        label='Email'
        placeholder='exemplo@gmail.com'
        InputLabelProps={{ shrink: true }}
      />

      <RHFCode name='code' />

      <LoadingButton
        fullWidth
        size='large'
        type='submit'
        variant='contained'
        loading={isSubmitting}
      >
        Verificar
      </LoadingButton>

      <Typography variant='body2'>
        {`Não tem um código? `}
        <Link
          variant='subtitle2'
          sx={{
            cursor: 'pointer',
          }}
        >
          Reenviar código
        </Link>
      </Typography>

      <Link
        component={RouterLink}
        href={paths.auth.login}
        color='inherit'
        variant='subtitle2'
        sx={{
          alignItems: 'center',
          display: 'inline-flex',
        }}
      >
        <Iconify icon='eva:arrow-ios-back-fill' width={16} />
        Retornar para entrar
      </Link>
    </Stack>
  )

  const renderHead = (
    <Stack spacing={1} sx={{ mb: 5 }}>
      <Typography variant='h3'>Por favor, verifique seu email!</Typography>

      <Typography variant='body2' sx={{ color: 'text.secondary' }}>
        Nós enviamos um código de confirmação de 6 dígitos para acb@dominio, por favor insira o
        código na caixa abaixo para verificar seu email.
      </Typography>
    </Stack>
  )

  return (
    <>
      <Helmet>
        <title>Verificação de Email</title>
      </Helmet>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        {renderHead}

        {renderForm}
      </FormProvider>
    </>
  )
}
