import { masks } from 'src/utils/masks'
import * as Yup from 'yup'

export const NewBudgetSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  clients: Yup.mixed().optional(),
  packages: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required(),
        package: Yup.mixed()
          .transform((value, originalValue) => {
            if (Array.isArray(originalValue)) {
              return originalValue.map((pkg) => pkg.value)
            }
            return value
          })
          .required('Package is required'),
      }),
    )
    .required('Packages is required'),
})

export const EditClientPackageSchema = Yup.object().shape({
  clients: Yup.array().of(
    Yup.object().shape({
      client: Yup.mixed(),
      pricing: Yup.mixed()
        .required('Preço para crianças é obrigatório')
        .transform((value) => +masks.unmask(value)),
      type: Yup.mixed().required('Tipo de cliente é obrigatório'),
    }),
  ),
  packageName: Yup.string().optional(),
  packageId: Yup.string().optional(),
})
