import { Autocomplete, FormControl, TextField } from '@mui/material'
import { SyntheticEvent, useCallback, useState } from 'react'
import { OptionType } from 'src/components/hook-form/rhf-autocomplete'
import useDebounce from 'src/hooks/use-debounce'
import useUsers from 'src/service/users.service'

export const SelectVendedores = ({ onFilters, filters }: { onFilters: any; filters: any }) => {
  const [query, setQuery] = useState('')
  const searchDebounce = useDebounce(query, 500)

  const { isLoading, isFetching, data } = useUsers(
    {
      page: 1,
      perPage: 100,
      sort: undefined,
    },
    {
      search: searchDebounce,
    },
  )

  const handleFilterService = useCallback(
    (event: SyntheticEvent<Element, Event>, selectedValues: any[]) => {
      onFilters('sellers', selectedValues)
    },
    [onFilters],
  )

  return (
    <FormControl
      sx={{
        flexShrink: 0,
        width: '100%',
      }}
    >
      <Autocomplete
        id='event-list'
        multiple
        options={data?.docs.map((option) => ({ label: option?.name, value: option?._id })) || []}
        isOptionEqualToValue={(option: any, value: any) => option?.value === value?.value}
        getOptionLabel={(option: OptionType | string) => (option as OptionType)?.label}
        onChange={handleFilterService}
        value={filters.salers}
        loading={isLoading || isFetching}
        inputValue={query}
        onInputChange={(event, newInputValue) => {
          setQuery(newInputValue)
        }}
        renderInput={(params) => <TextField label='Vendedores' {...params} />}
      />
    </FormControl>
  )
}
