import { useCallback, useState } from 'react'

import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Container from '@mui/material/Container'

import { Helmet } from 'react-helmet-async'
import Iconify from 'src/components/iconify'
import { paths } from 'src/routes/paths'
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs'
import AccountGeneral from './account-general'
// sections

const TABS = [
  {
    value: 'general',
    label: 'Perfil',
    icon: <Iconify icon='solar:user-id-bold' width={24} />,
  },
]

export default function SettingsPage() {
  const [currentTab, setCurrentTab] = useState('general')

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue)
  }, [])

  return (
    <>
      <Helmet>
        <title>Configurações</title>
      </Helmet>
      <Container maxWidth='lg'>
        <CustomBreadcrumbs
          heading='Configurações'
          links={[
            { name: 'Início', href: paths.dashboard.budgets.root },
            { name: 'Configurações' },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <Tabs
          value={currentTab}
          onChange={handleChangeTab}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        >
          {TABS.map((tab) => (
            <Tab key={tab.value} label={tab.label} icon={tab.icon} value={tab.value} />
          ))}
        </Tabs>

        {currentTab === 'general' && <AccountGeneral />}
      </Container>
    </>
  )
}
