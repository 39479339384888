const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
}

export const paths = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  page404: '/404',
  auth: {
    login: `${ROOTS.AUTH}/login`,
    register: `${ROOTS.AUTH}/register`,
    forgotPassword: `${ROOTS.AUTH}/forgot-password`,
    newPassword: `${ROOTS.AUTH}/new-password`,
    verify: `${ROOTS.AUTH}/verify`,
  },
  // DASHBOARD
  dashboard: {
    root: `${ROOTS.DASHBOARD}/budgets`,
    user: `${ROOTS.DASHBOARD}/users`,
    clients: `${ROOTS.DASHBOARD}/clients`,
    packages: `${ROOTS.DASHBOARD}/packages`,
    budgets: {
      root: `${ROOTS.DASHBOARD}/budgets`,
      create: `${ROOTS.DASHBOARD}/budgets/create`,
      preview: (id: string) => `${ROOTS.DASHBOARD}/budgets/${id}/preview`,
    },
    settings: `${ROOTS.DASHBOARD}/settings`,
  },
}
