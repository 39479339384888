import * as Yup from 'yup'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
// @mui
import LoadingButton from '@mui/lab/LoadingButton'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import InputAdornment from '@mui/material/InputAdornment'
// rotas
import { paths } from 'src/routes/paths'
import { RouterLink } from 'src/routes/components'
// hooks
import { useBoolean } from 'src/hooks/use-boolean'
// componentes
import Iconify from 'src/components/iconify'
import FormProvider, { RHFTextField } from 'src/components/hook-form'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'src/routes/hook'
import { isValidToken, jwtDecode } from 'src/auth/context/jwt/utils'
import { useNavigate } from 'react-router'
import axiosInstance from 'src/utils/axios'
import { useSnackbar } from 'notistack'

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string
  password: string
  confirmPassword: string
}

export default function NewPasswordPage() {
  const senha = useBoolean()

  const { token } = useParams()
  const { enqueueSnackbar } = useSnackbar()

  const navigate = useNavigate()

  const NewPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email é obrigatório')
      .email('Email deve ser um endereço de email válido'),
    password: Yup.string()
      .min(6, 'Senha deve ter pelo menos 6 caracteres')
      .required('Senha é obrigatória'),
    confirmPassword: Yup.string()
      .required('Confirmação de senha é obrigatória')
      .oneOf([Yup.ref('password')], 'Senhas devem coincidir'),
  })

  const defaultValues = {
    email: '',
    password: '',
    confirmPassword: '',
  }

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(NewPasswordSchema),
    defaultValues,
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const onSubmit = useCallback(
    async (data: FormValuesProps) => {
      try {
        await axiosInstance.post('/changePassword', { password: data.password, token })

        enqueueSnackbar('Senha alterada com sucesso!')
        navigate(paths.auth.login)
      } catch (error) {
        if (error?.message) {
          enqueueSnackbar(error?.message, {
            variant: 'error',
          })
        } else {
          enqueueSnackbar('Erro ao alterar senha!', {
            variant: 'error',
          })
        }
      }
    },
    [enqueueSnackbar, navigate, token],
  )

  useEffect(() => {
    if (token && isValidToken(token)) {
      const decript = jwtDecode(token)
      methods.setValue('email', decript.email)
    } else {
      enqueueSnackbar('Token de recuperação expirado!', {
        variant: 'error',
      })
      navigate(paths.auth.login)
    }
  }, [token, navigate, methods, enqueueSnackbar])

  const renderForm = (
    <Stack spacing={3} alignItems='center'>
      <RHFTextField
        name='email'
        label='Email'
        disabled
        placeholder='exemplo@gmail.com'
        InputLabelProps={{ shrink: true }}
      />

      {/* <RHFCode name='code' /> */}

      <RHFTextField
        name='password'
        autoComplete='new-password'
        label='Senha'
        type={senha.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton onClick={senha.onToggle} edge='end'>
                <Iconify icon={senha.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <RHFTextField
        name='confirmPassword'
        label='Confirmar Nova Senha'
        autoComplete='new-password'
        type={senha.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton onClick={senha.onToggle} edge='end'>
                <Iconify icon={senha.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <LoadingButton
        fullWidth
        size='large'
        type='submit'
        variant='contained'
        loading={isSubmitting}
      >
        Atualizar Senha
      </LoadingButton>

      {/* <Typography variant='body2'>
        {`Não tem um código? `}
        <Link
          variant='subtitle2'
          sx={{
            cursor: 'pointer',
          }}
        >
          Reenviar email
        </Link>
      </Typography> */}

      <Link
        component={RouterLink}
        href={paths.auth.login}
        color='inherit'
        variant='subtitle2'
        sx={{
          alignItems: 'center',
          display: 'inline-flex',
        }}
      >
        <Iconify icon='eva:arrow-ios-back-fill' width={16} />
        Retornar para entrar
      </Link>
    </Stack>
  )

  const renderHead = (
    <Stack spacing={1} sx={{ mb: 5 }}>
      <Typography variant='h3'>Solicitação recebebida com sucesso!</Typography>

      <Typography variant='body2' sx={{ color: 'text.secondary' }}>
        Por favor, insira abaixo sua nova senha para alteração.
      </Typography>
    </Stack>
  )

  return (
    <>
      <Helmet>
        <title>Mudar Senha</title>
      </Helmet>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        {renderHead}

        {renderForm}
      </FormProvider>
    </>
  )
}
