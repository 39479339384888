import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
// auth
import { RoleBasedGuard } from 'src/auth/guard'
// layouts
// components
import { LoadingScreen } from 'src/components/loading-screen'
import DashboardLayout from 'src/layout/dashboard/layout'
import CreateBudget from 'src/pages/dashboard/budgets/create'
import BudgetsListView from 'src/pages/dashboard/budgets/list'
import { BudgetPreview } from 'src/pages/dashboard/budgets/preview'

import ClientListView from 'src/pages/dashboard/clients/list'
import PackageListView from 'src/pages/dashboard/packages/list'
import SettingsPage from 'src/pages/dashboard/settings'
import UserListView from 'src/pages/dashboard/users/list'

// OVERVIEW
/* const IndexPage = lazy(() => import('src/pages/dashboard/budgets/list'))
 */
// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <RoleBasedGuard hasContent roles={['admin', 'user']}>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </RoleBasedGuard>
    ),
    children: [
      /*    { element: <IndexPage />, index: true }, */
      { path: 'settings', element: <SettingsPage /> },
      {
        path: 'users',
        children: [{ element: <UserListView />, index: true }],
      },
      {
        path: 'clients',
        children: [{ element: <ClientListView />, index: true }],
      },
      {
        path: 'packages',
        children: [{ element: <PackageListView />, index: true }],
      },
      {
        path: 'budgets',
        children: [
          { element: <BudgetsListView />, index: true },
          {
            element: <CreateBudget />,
            path: 'create',
          },
          {
            element: <BudgetPreview />,
            path: ':id/preview',
          },
        ],
      },
    ],
  },
]
