/* eslint-disable import/no-extraneous-dependencies */
import { useFormContext, Controller } from 'react-hook-form'
// @mui
import { TextFieldProps } from '@mui/material/TextField'

import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js'
import { useEffect, useState } from 'react'
// ----------------------------------------------------------------------
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

type Props = TextFieldProps & {
  name: string
  mask?: string
}

export default function RHFEditor({
  name,
  mask,
  helperText,
  label,
  type,
  onBlur,
  ...other
}: Props) {
  const { control, getValues } = useFormContext()

  // Pega o valor inicial do campo
  const initialValue = getValues(name)

  // Converte o HTML inicial para EditorState
  const blocksFromHTML = convertFromHTML(initialValue || '')
  const contentState = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap,
  )
  /*   useEffect(() => {
    setEditorState(EditorState.createWithContent(contentState))
  }, [contentState, initialValue]) */

  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState))

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const onEditorStateChange = (editorState2: any) => {
          setEditorState(editorState2)

          field.onChange(draftToHtml(convertToRaw(editorState2.getCurrentContent())))
        }

        return (
          <Editor
            editorState={editorState}
            wrapperClassName='wrapper-class'
            editorClassName='editor-class'
            onBlur={onBlur}
            editorStyle={{
              border: '1px solid #f1f1f1',
              borderRadius: 10,
              minHeight: 200,
              padding: 10,
            }}
            onEditorStateChange={onEditorStateChange}
          />
        )
      }}
    />
  )
}
