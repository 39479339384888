// eslint-disable

import { useQuery } from '@tanstack/react-query'
import { Pagination } from 'src/components/datagrid'
import { IUserItem } from 'src/types/user'
import axiosInstance from 'src/utils/axios'

type Users = {
  docs: IUserItem[]
  page: number
  perPage: number
  totalPages: number
  totalDocs: number
}

async function fetchUser(pagination: Pagination, filters: any): Promise<Users> {
  const res = await axiosInstance.get('/users', {
    params: {
      ...pagination,
      ...filters,
    },
  })
  return res.data
}

function useUsers(pagination: Pagination, filters?: any) {
  return useQuery(['users', pagination, filters], () => fetchUser(pagination, filters), {
    keepPreviousData: true,
    staleTime: 50000,
  })
}

export default useUsers
