// eslint-disable

import { useQuery } from '@tanstack/react-query'
// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns'
// eslint-disable-next-line import/no-duplicates
import { ptBR } from 'date-fns/locale'
import { Loading } from 'notiflix'
import { enqueueSnackbar } from 'notistack'
import { Pagination } from 'src/components/datagrid'
import { download } from 'src/hooks/use-download'
import { IUserItem } from 'src/types/user'
import axiosInstance from 'src/utils/axios'

type Users = {
  docs: IUserItem[]
  page: number
  perPage: number
  totalPages: number
  totalDocs: number
}

async function fetchBudget(pagination: Pagination, filters: any): Promise<Users> {
  const res = await axiosInstance.get('/budgets', {
    params: {
      ...pagination,
      ...filters,
    },
  })
  return res.data
}

async function getById(id: string) {
  const res = await axiosInstance.get(`/budgets/${id}`)
  return res.data
}
export function useFetchBudgetById(id: string) {
  return useQuery(['budgetsBYId', id], () => getById(id), {
    keepPreviousData: true,
    staleTime: 50000,
  })
}
export async function handleGeneratePdf(id: string, name: string) {
  try {
    Loading.circle('Gerando PDF')
    const pdfLink = await axiosInstance.put(`/budgets/${id}/pdf`)
    Loading.change('PDF gerado com sucesso!')

    download(pdfLink.data, `${name}.pdf`)
    Loading.remove()
  } catch (error) {
    Loading.remove()
    if (error?.message) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      })
    } else {
      enqueueSnackbar('Erro na requisição!', {
        variant: 'error',
      })
    }
  }
}

export async function handleDeleteSection(id: string, name: string, refetch: () => void) {
  try {
    await axiosInstance.delete(`/budgets/${id}/package/${name}/section`)
    enqueueSnackbar(
      `Sessão ${format(new Date(name), 'dd/MM/yyyy - EEE', {
        locale: ptBR,
      })} deletada com sucesso`,
      {
        variant: 'success',
      },
    )
    refetch()
  } catch (error) {
    if (error?.message) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      })
    } else {
      enqueueSnackbar('Erro na requisição!', {
        variant: 'error',
      })
    }
  }
}

export async function handleAddSection(id: string, name: string, refetch: () => void) {
  return 'deu bom'
}

function useBudgets(pagination: Pagination, filters?: any) {
  return useQuery(['budgets', pagination, filters], () => fetchBudget(pagination, filters), {
    keepPreviousData: true,
    staleTime: 50000,
  })
}

export default useBudgets
