import * as Yup from 'yup'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
// @mui
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'

import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'
// utils
import { fData } from 'src/utils/format-number'
// components
import { useSnackbar } from 'src/components/snackbar'
import FormProvider, { RHFTextField, RHFUploadAvatar } from 'src/components/hook-form'
import { useUserStore } from 'src/store/user'
import axios, { API_ENDPOINTS } from 'src/utils/axios'
import { IUserAccount } from 'src/types/user'
import { useBoolean } from 'src/hooks/use-boolean'
import { IconButton, InputAdornment } from '@mui/material'
import Iconify from 'src/components/iconify'

export default function AccountGeneral() {
  const { enqueueSnackbar } = useSnackbar()

  const { user, setUser } = useUserStore()

  const password = useBoolean()

  const UpdateUserSchema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    email: Yup.string()
      .required('Email é obrigatório')
      .email('O e-mail deve ser um endereço de e-mail válido'),
    photo: Yup.mixed().nullable(),
  })

  const defaultValues = {
    name: user?.name || '',
    email: user?.email || '',
    photo: user?.photo || null,
  }

  const methods = useForm<IUserAccount>({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  })

  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const onSubmit = useCallback(
    async (data: IUserAccount) => {
      try {
        const payload: any = {
          name: data.name,
        }

        if (data.password && data.password !== '') {
          payload.password = data.password
        }
        const { data: response } = await axios.put(API_ENDPOINTS.user.editMe, payload)

        setUser(response)

        if (typeof data.photo !== 'string' && data.photo) {
          const formDataUpload: FormData = new FormData()
          formDataUpload.append('photo', data.photo)
          try {
            await axios.put(API_ENDPOINTS.user.editPhoto, formDataUpload)
            enqueueSnackbar('Foto de perfil atualizada com sucesso !')
          } catch (error) {
            enqueueSnackbar('Erro ao enviar a foto perfil !', {
              variant: 'error',
            })
          }
        }

        enqueueSnackbar('Perfil Atualizado com sucesso !')
      } catch (error) {
        enqueueSnackbar('Erro ao editar perfil !', {
          variant: 'error',
        })
      }
    },
    [enqueueSnackbar, setUser],
  )

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0]

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      })

      if (file) {
        setValue('photo', newFile, { shouldValidate: true })
      }
    },
    [setValue],
  )

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid xs={12} md={4}>
          <Card sx={{ pt: 10, pb: 5, px: 3, textAlign: 'center' }}>
          <RHFUploadAvatar
              name='photo'
              maxSize={3145728}
              onDrop={handleDrop}
              helperText={
                <Typography
                  variant='caption'
                  sx={{
                    mt: 3,
                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                    color: 'text.disabled',
                  }}
                >
                  Permitido *.jpeg, *.jpg, *.png, *.gif
                  <br /> tamanho máximo de {fData(3145728)}
                </Typography>
              }
            />
          </Card>
        </Grid>

        <Grid xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display='grid'
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField name='name' label='Nome' />
              <RHFTextField name='email' label='Email' />
              <RHFTextField
                name='password'
                label='Senha'
                autoComplete='new-password'
                type={password.value ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={password.onToggle} edge='end'>
                        <Iconify
                          icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Stack spacing={3} alignItems='flex-end' sx={{ mt: 3 }}>
              <LoadingButton type='submit' variant='contained' loading={isSubmitting}>
                Salvar
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  )
}
