import { enqueueSnackbar } from 'notistack'
import axiosInstance from 'src/utils/axios'

export const updateOrder = async (id: string, data: any) => {
  try {
    console.log('data2', data)
    console.log('id', id)
    await axiosInstance.put(`/budgets/${id}`, {
      ...data,
    })

    enqueueSnackbar('Ordem alterada com sucesso!')
  } catch (error) {
    if (error?.message) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      })
    } else {
      enqueueSnackbar('Erro na requisição!', {
        variant: 'error',
      })
    }
  }
}
