/* eslint-disable import/no-duplicates */
import React, { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'

// @mui
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'

import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'

// types
// components
import { useSnackbar } from 'src/components/snackbar'
import FormProvider from 'src/components/hook-form'
import axiosInstance from 'src/utils/axios'
import { Grid } from '@mui/material'

import RHFCalendar from 'src/components/hook-form/rhf-calendar'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'

type Props = {
  open: boolean
  onClose: VoidFunction
  onRefleshTable: VoidFunction
  currentUser?: { _id: string; name?: string }
}

export default function EditSection({ onRefleshTable, currentUser, open, onClose }: Props) {
  const { enqueueSnackbar } = useSnackbar()

  const defaultValues = useMemo(
    () => ({
      ...currentUser,
      name: new Date(currentUser?.name! || new Date()),
    }),
    [currentUser],
  )

  const methods = useForm<any>({ defaultValues })

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, reset])

  const onSubmit = useCallback(
    async (data: any) => {
      try {
        await axiosInstance.put(
          `/budgets/${currentUser?._id}/package/${currentUser?.name}/section`,
          {
            newName: data.name,
          },
        )

        reset()
        onClose()
        onRefleshTable()
        enqueueSnackbar('Alteração realizada com sucesso!')
      } catch (error) {
        if (error?.message) {
          enqueueSnackbar(error?.message, {
            variant: 'error',
          })
        } else {
          enqueueSnackbar('Erro na requisição!', {
            variant: 'error',
          })
        }
      }
    },
    [enqueueSnackbar, onClose, reset, currentUser, onRefleshTable],
  )

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods}>
        <DialogTitle>
          Editar sessão{' '}
          {format(new Date(currentUser?.name || new Date()), 'dd/MM/yyyy - EEE', {
            locale: ptBR,
          })}
        </DialogTitle>

        <DialogContent>
          <Box padding={1}>
            <Grid item xs={6} spacing={1}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <RHFCalendar name='name' label='Data' />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant='outlined' onClick={onClose} color='error'>
            Cancelar
          </Button>

          <LoadingButton
            type='button'
            onClick={handleSubmit(onSubmit)}
            variant='contained'
            loading={isSubmitting}
          >
            Adicionar
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  )
}
