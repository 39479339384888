// eslint-disable

import { useQuery } from '@tanstack/react-query'
import { Pagination } from 'src/components/datagrid'
import { IUserItem } from 'src/types/user'
import axiosInstance from 'src/utils/axios'

type Users = {
  docs: IUserItem[]
  page: number
  perPage: number
  totalPages: number
  totalDocs: number
}

type PackageAutoComplete = {
  value: string
  label: string
}

async function fetchAutoCompleteClients(): Promise<PackageAutoComplete[]> {
  const res = await axiosInstance.get('/clients/autocomplete')
  return res.data
}

async function fetchClient(pagination: Pagination, filters: any): Promise<Users> {
  const res = await axiosInstance.get('/clients', {
    params: {
      ...pagination,
      ...filters,
    },
  })
  return res.data
}

function useClients(pagination: Pagination, filters?: any) {
  return useQuery(['clients', pagination, filters], () => fetchClient(pagination, filters), {
    keepPreviousData: true,
    staleTime: 50000,
  })
}

export function useClientAutoComplete() {
  return useQuery(['clientsAutoCOmplete'], fetchAutoCompleteClients, {
    keepPreviousData: true,
    staleTime: 50000,
  })
}

export default useClients
