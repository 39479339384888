import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'

// @mui
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'

import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'

// types
// components
import { useSnackbar } from 'src/components/snackbar'
import FormProvider, { RHFTextField } from 'src/components/hook-form'
import axiosInstance from 'src/utils/axios'
import { IUserItem } from 'src/types/user'
import RHFAutocomplete from 'src/components/hook-form/rhf-autocomplete'
import { Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { EditClientPackageSchema } from 'src/validators/budget.schemas'
import Iconify from 'src/components/iconify'
import ClientDeleteBudget from 'src/components/custom-dialog/clients-delete-budget-dialog'
import { useBoolean } from 'src/hooks/use-boolean'

type Props = {
  open: boolean
  onClose: VoidFunction
  onRefleshTable: VoidFunction
  currentUser?: { clients: any[]; _id: string; packageId: string; packageName: string }
}

export default function EditClients({ onRefleshTable, currentUser, open, onClose }: Props) {
  const { enqueueSnackbar } = useSnackbar()

  const clientDeleteBudget = useBoolean()
  const [clientDeleteId, setClientDeleteId] = React.useState('')

  const defaultValues = useMemo(
    () => ({
      ...currentUser,
    }),
    [currentUser],
  )

  const methods = useForm<any>({
    resolver: yupResolver(EditClientPackageSchema),
    defaultValues,
  })

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const onSubmit = useCallback(
    async (data: IUserItem) => {
      try {
        await axiosInstance.put(
          `/budgets/${currentUser?._id}/package/${currentUser?.packageId}/clients`,
          {
            ...data,
            packageName: currentUser?.packageName,
          },
        )

        reset()
        onClose()
        onRefleshTable()
        enqueueSnackbar(
          currentUser ? 'Alteração realizada com sucesso!' : 'Cliente criada com sucesso!',
        )
      } catch (error) {
        if (error?.message) {
          enqueueSnackbar(error?.message, {
            variant: 'error',
          })
        } else {
          enqueueSnackbar('Erro na requisição!', {
            variant: 'error',
          })
        }
      }
    },
    [enqueueSnackbar, onClose, reset, currentUser, onRefleshTable],
  )

  const removeClient = (all: boolean) => {
    try {
      if (all) {
        axiosInstance.delete(`/budgets/${currentUser?._id}/clients/${clientDeleteId}`)
      } else {
        axiosInstance.delete(
          `/budgets/${currentUser?._id}/clients/${clientDeleteId}/package/${currentUser?.packageId}/packageName/${currentUser?.packageName}`,
        )
      }
      clientDeleteBudget.onFalse()
      onRefleshTable()
      enqueueSnackbar('Cliente deletado com sucesso!')
    } catch {
      enqueueSnackbar('Erro ao deletar cliente', {
        variant: 'error',
      })
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods}>
        <DialogTitle>Atualizar preços/tipo</DialogTitle>

        <DialogContent>
          <Box padding={1}>
            <Grid container spacing={1} direction='row'>
              {currentUser?.clients?.map((item: any, index: number) => (
                <Grid
                  item
                  xs={6}
                  key={index}
                  spacing={1}
                  sx={{
                    border: '1px solid #000',
                    borderRadius: '4px', // Adiciona bordas arredondadas
                    padding: '8px', // Adiciona espaçamento interno
                    boxSizing: 'border-box',
                    // Garante que o padding não afete o tamanho total do grid
                  }}
                >
                  <Grid container direction='row' alignItems='center'>
                    <Grid item xs={10} sx={{ mb: 1 }}>
                      <Typography variant='h6'>{item.client.name}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Tooltip title='Deletar' placement='top' arrow>
                        <IconButton
                          onClick={() => {
                            clientDeleteBudget.onTrue()
                            setClientDeleteId(item.client._id)
                          }}
                        >
                          <Iconify color='red' icon='solar:trash-bin-trash-bold' />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <RHFAutocomplete
                        options={[
                          { label: 'Adulto', value: 'adults0' },
                          { label: 'Criança 1 ', value: 'children0' },
                          { label: 'Criança 2', value: 'children1' },
                          { label: 'Criança 3', value: 'children2' },
                          { label: 'Criança 4', value: 'children3' },
                          { label: 'Criança 5', value: 'children4' },
                          { label: 'Criança 6', value: 'children5' },
                          { label: 'Criança 7', value: 'children6' },
                          { label: 'Criança 8', value: 'children7' },
                          { label: 'Criança 9', value: 'children8' },
                          { label: 'Criança 10', value: 'children9' },
                          { label: 'Criança 11', value: 'children10' },
                          { label: 'Criança 12', value: 'children11' },
                          { label: 'Idoso', value: 'seniors0' },
                          { label: 'Professor', value: 'teacher' },
                          { label: 'Professor PR', value: 'teacherPR' },
                          { label: 'Doador de Sangue', value: 'bloodDonor' },
                          { label: 'Identidade Jovem', value: 'idYoung' },
                          { label: 'Militar', value: 'military' },
                          { label: 'Estudante', value: 'student' },
                          { label: 'Estudante +16', value: 'studentAged' },
                          { label: 'Residente', value: 'resident' },
                          { label: 'PCD', value: 'pcd' },
                        ]}
                        name={`clients.${index}.type`}
                        label='Tipo'
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <RHFTextField
                        name={`clients.${index}.pricing`}
                        label='Preço'
                        mask='currency'
                        defaultValue={item.pricing}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
        </DialogContent>

        <ClientDeleteBudget
          open={clientDeleteBudget.value}
          onClose={clientDeleteBudget.onFalse}
          title='Deletar cliente'
          action={
            <>
              <Button
                variant='contained'
                color='error'
                onClick={() => {
                  removeClient(true)
                }}
              >
                Deletar para todos
              </Button>
              <Button
                variant='contained'
                color='error'
                onClick={() => {
                  removeClient(false)
                }}
              >
                Deletar somente aqui
              </Button>
            </>
          }
        />

        <DialogActions>
          <Button variant='outlined' onClick={onClose} color='error'>
            Cancelar
          </Button>

          <LoadingButton
            type='button'
            onClick={handleSubmit(onSubmit)}
            variant='contained'
            loading={isSubmitting}
          >
            Atualizar
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  )
}
