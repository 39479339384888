import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'

// @mui
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'

import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'

// types
// components
import { useSnackbar } from 'src/components/snackbar'
import FormProvider from 'src/components/hook-form'
import axiosInstance from 'src/utils/axios'
import RHFAutocomplete from 'src/components/hook-form/rhf-autocomplete'
import { Grid } from '@mui/material'

import { usePackageAutoComplete } from 'src/service/packages.service'
import { useClientAutoComplete } from 'src/service/clients.service'
import { useBoolean } from 'src/hooks/use-boolean'
import MyAutocomplete from 'src/components/hook-form/autocomplete-cliente'
import ClientCreateEditForm from '../../clients/client-create-edit-view'

type Props = {
  open: boolean
  onClose: VoidFunction
  onRefleshTable: VoidFunction
  currentUser?: { _id: string }
}

export default function AddClient({ onRefleshTable, currentUser, open, onClose }: Props) {
  const { enqueueSnackbar } = useSnackbar()
  const [selectedClients, setSelectedClients] = React.useState([])
  const clients = useClientAutoComplete()
  const quickEdit = useBoolean()
  const methods = useForm<any>({
    /*     resolver: yupResolver(EditClientPackageSchema), */
  })
  // @ts-ignore
  const handleSetValue = (newValues) => {
    setSelectedClients(newValues) // Atualiza o estado com os clientes selecionados
  }
  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const onSubmit = useCallback(
    async (data: any) => {
      try {
        await axiosInstance.post(`/budgets/${currentUser?._id}/clients`, {
          // @ts-ignore
          clients: selectedClients.map((client) => client.value),
        })

        reset()
        onClose()
        onRefleshTable()
        enqueueSnackbar(
          currentUser ? 'Alteração realizada com sucesso!' : 'Cliente criada com sucesso!',
        )
      } catch (error) {
        if (error?.message) {
          enqueueSnackbar(error?.message, {
            variant: 'error',
          })
        } else {
          enqueueSnackbar('Erro na requisição!', {
            variant: 'error',
          })
        }
      }
    },
    [enqueueSnackbar, onClose, reset, currentUser, onRefleshTable, selectedClients],
  )

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods}>
        <DialogTitle>Adicionar passageiros</DialogTitle>

        <DialogContent>
          <Box padding={1}>
            <Grid item xs={6} spacing={1}>
              <Grid
                container
                spacing={1}
                direction='row'
                justifyContent='center'
                alignItems='center'
              >
                <Grid item xs={9}>
                  <MyAutocomplete clients={clients || []} setValue={handleSetValue} />
                </Grid>
                <Grid item xs={3}>
                  <Button
                    onClick={() => quickEdit.onTrue()}
                    variant='contained'
                    sx={{ flexBasis: 'calc(20% - 8px)' }}
                  >
                    Incluir cliente
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant='outlined' onClick={onClose} color='error'>
            Cancelar
          </Button>

          <LoadingButton
            type='button'
            onClick={handleSubmit(onSubmit)}
            variant='contained'
            loading={isSubmitting}
          >
            Adicionar
          </LoadingButton>
        </DialogActions>
      </FormProvider>
      <ClientCreateEditForm
        onRefleshTable={() => clients.refetch()}
        open={quickEdit.value}
        onClose={quickEdit.onFalse}
      />
    </Dialog>
  )
}
