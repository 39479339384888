// @mui
import Button from '@mui/material/Button'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import ListItemText from '@mui/material/ListItemText'

// hooks
import { useBoolean } from 'src/hooks/use-boolean'
// types
import { IUserItem } from 'src/types/user'
// components

import { ConfirmDialog } from 'src/components/custom-dialog'

import { fCurrency } from 'src/utils/format-number'
import { getTotalPricingByType } from 'src/utils/priceClientPackage'
import FormProvider, { RHFTextField } from 'src/components/hook-form'
import { useForm } from 'react-hook-form'
import { useCallback } from 'react'
import axiosInstance from 'src/utils/axios'
import { enqueueSnackbar } from 'src/components/snackbar'
import { IconButton, Tooltip } from '@mui/material'
import Iconify from 'src/components/iconify'
import EditClients from '../edit-clients'

//

// ----------------------------------------------------------------------

export const roleName = {
  admin: 'Administrador',
  user: 'Usuário',
  franchisee: 'Franqueado',
}

type Props = {
  selected: boolean
  onEditRow: VoidFunction
  row: any
  refetch: VoidFunction
  onSelectRow: VoidFunction
  onDeleteRow: VoidFunction
  tableHead: any
  budgetId: string
  packageName: string
}

export default function BudgetPreviewTableRow({
  row,
  selected,
  refetch,
  onEditRow,
  onSelectRow,
  onDeleteRow,
  tableHead,
  budgetId,
  packageName,
}: Props) {
  const confirm = useBoolean()

  const pricing = getTotalPricingByType(row.clients, tableHead)

  const quickEdit = useBoolean()

  const methods = useForm<any>({
    /*     resolver: yupResolver(EditClientPackageSchema), */
    defaultValues: { observation: row.observation },
  })

  const { handleSubmit } = methods

  async function handleDeletePackageByIndex() {
    try {
      await axiosInstance.put(`/budgets/${budgetId}/package/${row.package._id}/remove`, {
        packageName,
      })
      enqueueSnackbar('Pacote deletado com sucesso!', {
        variant: 'success',
      })
      refetch()
    } catch (error) {
      if (error?.message) {
        enqueueSnackbar(error?.message, {
          variant: 'error',
        })
      } else {
        enqueueSnackbar('Erro na requisição!', {
          variant: 'error',
        })
      }
    }
  }

  const onSubmit = useCallback(
    async (data: IUserItem) => {
      try {
        await axiosInstance.put(`/budgets/${budgetId}/package/${row.package._id}/observation`, {
          ...data,
          packageName,
        })

        enqueueSnackbar('Observação alterada com sucesso!')
      } catch (error) {
        if (error?.message) {
          enqueueSnackbar(error?.message, {
            variant: 'error',
          })
        } else {
          enqueueSnackbar('Erro na requisição!', {
            variant: 'error',
          })
        }
      }
    },
    [budgetId, packageName, row.package._id],
  )

  return (
    <>
      <TableRow hover>
        <TableCell
          sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => quickEdit.onTrue()}
        >
          <ListItemText
            primary={row.package.name}
            secondary={null}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
          />
        </TableCell>
        <TableCell>
          <FormProvider methods={methods}>
            <RHFTextField name='observation' onBlur={handleSubmit(onSubmit)} />
          </FormProvider>
        </TableCell>

        {pricing.pcd !== undefined && (
          <TableCell sx={{ whiteSpace: 'nowrap' }}>{fCurrency(pricing.pcd)}</TableCell>
        )}
        {pricing.student !== undefined && (
          <TableCell sx={{ whiteSpace: 'nowrap' }}>{fCurrency(pricing.student)}</TableCell>
        )}
        {pricing.resident !== undefined && (
          <TableCell sx={{ whiteSpace: 'nowrap' }}>{fCurrency(pricing.resident)}</TableCell>
        )}

        {pricing.studentAged !== undefined && (
          <TableCell sx={{ whiteSpace: 'nowrap' }}>{fCurrency(pricing.studentAged)}</TableCell>
        )}

        {pricing.teacher !== undefined && (
          <TableCell sx={{ whiteSpace: 'nowrap' }}>{fCurrency(pricing.teacher)}</TableCell>
        )}

        {pricing.teacherPR !== undefined && (
          <TableCell sx={{ whiteSpace: 'nowrap' }}>{fCurrency(pricing.teacherPR)}</TableCell>
        )}

        {pricing.bloodDonor !== undefined && (
          <TableCell sx={{ whiteSpace: 'nowrap' }}>{fCurrency(pricing.bloodDonor)}</TableCell>
        )}

        {pricing.idYoung !== undefined && (
          <TableCell sx={{ whiteSpace: 'nowrap' }}>{fCurrency(pricing.idYoung)}</TableCell>
        )}
        {pricing.military !== undefined && (
          <TableCell sx={{ whiteSpace: 'nowrap' }}>{fCurrency(pricing.military)}</TableCell>
        )}
        {pricing.children0 !== undefined && (
          <TableCell sx={{ whiteSpace: 'nowrap' }}>{fCurrency(pricing.children0)}</TableCell>
        )}
        {pricing.children1 !== undefined && (
          <TableCell sx={{ whiteSpace: 'nowrap' }}>{fCurrency(pricing.children1)}</TableCell>
        )}
        {pricing.children2 !== undefined && (
          <TableCell sx={{ whiteSpace: 'nowrap' }}>{fCurrency(pricing.children2)}</TableCell>
        )}
        {pricing.children3 !== undefined && (
          <TableCell sx={{ whiteSpace: 'nowrap' }}>{fCurrency(pricing.children3)}</TableCell>
        )}
        {pricing.children4 !== undefined && (
          <TableCell sx={{ whiteSpace: 'nowrap' }}>{fCurrency(pricing.children4)}</TableCell>
        )}
        {pricing.children5 !== undefined && (
          <TableCell sx={{ whiteSpace: 'nowrap' }}>{fCurrency(pricing.children5)}</TableCell>
        )}
        {pricing.children6 !== undefined && (
          <TableCell sx={{ whiteSpace: 'nowrap' }}>{fCurrency(pricing.children6)}</TableCell>
        )}
        {pricing.children7 !== undefined && (
          <TableCell sx={{ whiteSpace: 'nowrap' }}>{fCurrency(pricing.children7)}</TableCell>
        )}
        {pricing.children8 !== undefined && (
          <TableCell sx={{ whiteSpace: 'nowrap' }}>{fCurrency(pricing.children8)}</TableCell>
        )}
        {pricing.children9 !== undefined && (
          <TableCell sx={{ whiteSpace: 'nowrap' }}>{fCurrency(pricing.children9)}</TableCell>
        )}
        {pricing.children10 !== undefined && (
          <TableCell sx={{ whiteSpace: 'nowrap' }}>{fCurrency(pricing.children10)}</TableCell>
        )}
        {pricing.children11 !== undefined && (
          <TableCell sx={{ whiteSpace: 'nowrap' }}>{fCurrency(pricing.children11)}</TableCell>
        )}

        {pricing.adults0 !== undefined && (
          <TableCell sx={{ whiteSpace: 'nowrap' }}>{fCurrency(pricing.adults0)}</TableCell>
        )}
        {pricing.seniors0 !== undefined && (
          <TableCell sx={{ whiteSpace: 'nowrap' }}>{fCurrency(pricing.seniors0)}</TableCell>
        )}
        <TableCell align='right' sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Tooltip title='Editar' placement='top' arrow>
            <IconButton onClick={() => quickEdit.onTrue()}>
              <Iconify icon='solar:pen-bold' />
            </IconButton>
          </Tooltip>
          <Tooltip title='Deletar' placement='top' arrow>
            <IconButton
              onClick={() => {
                confirm.onTrue()
              }}
            >
              <Iconify color='red' icon='solar:trash-bin-trash-bold' />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>

      <EditClients
        currentUser={{
          clients: row.clients,
          _id: budgetId,
          packageId: row.package._id,
          packageName,
        }}
        onRefleshTable={() => refetch()}
        open={quickEdit.value}
        onClose={quickEdit.onFalse}
      />

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title='Delete'
        content='Tem certeza que quer deletar?'
        action={
          <Button variant='contained' color='error' onClick={() => handleDeletePackageByIndex()}>
            Deletar
          </Button>
        }
      />
    </>
  )
}
