// @mui
import Button from '@mui/material/Button'
import Avatar from '@mui/material/Avatar'
import TableRow from '@mui/material/TableRow'
import Checkbox from '@mui/material/Checkbox'
import TableCell from '@mui/material/TableCell'
import IconButton from '@mui/material/IconButton'
import ListItemText from '@mui/material/ListItemText'
import Tooltip from '@mui/material/Tooltip'
import { useRouter } from 'src/routes/hook'
// hooks
import { useBoolean } from 'src/hooks/use-boolean'
// types
import { IUserItem } from 'src/types/user'
// components
import Label, { LabelColor } from 'src/components/label'
import Iconify from 'src/components/iconify'
import { ConfirmDialog } from 'src/components/custom-dialog'
import { format } from 'date-fns'

import { IPackageItem } from 'src/types/package'
import { download } from 'src/hooks/use-download'
import { paths } from 'src/routes/paths'

//

// ----------------------------------------------------------------------

export const roleName = {
  admin: 'Administrador',
  user: 'Usuário',
  franchisee: 'Franqueado',
}

const colorsStatus: any = {
  NEW: 'info',
  DONE: 'success',
  CLOSED: 'error',
}

type Props = {
  selected: boolean
  onEditRow: VoidFunction
  row: IPackageItem
  refetch: VoidFunction
  onSelectRow: VoidFunction
  onDeleteRow: VoidFunction
}

export default function UserTableRow({
  row,
  selected,
  refetch,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}: Props) {
  const { name, createdAt } = row

  const confirm = useBoolean()

  const router = useRouter()

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell padding='checkbox'>
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>

        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
          <ListItemText
            primary={name}
            secondary={null}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
          />
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {row.pdfUrl ? (
            <Label onClick={() => download(row.pdfUrl)} color='primary' sx={{ cursor: 'pointer' }}>
              PDF
            </Label>
          ) : (
            <Label color='error' sx={{ cursor: 'pointer' }}>
              Sem PDF
            </Label>
          )}
        </TableCell>
        <TableCell>
          <Label
            variant='soft'
            color={`${colorsStatus[row.status as keyof LabelColor] as LabelColor}`}
          >
            {row.status}
          </Label>
        </TableCell>
        <TableCell>{row.seller.name}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {format(new Date(createdAt), 'dd/MM/yyyy HH:mm')}
        </TableCell>

        <TableCell align='right' sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Tooltip title='Editar' placement='top' arrow>
            <IconButton onClick={() => router.push(paths.dashboard.budgets.preview(row._id))}>
              <Iconify icon='solar:pen-bold' />
            </IconButton>
          </Tooltip>
          <Tooltip title='Deletar' placement='top' arrow>
            <IconButton
              onClick={() => {
                confirm.onTrue()
              }}
            >
              <Iconify color='red' icon='solar:trash-bin-trash-bold' />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title='Delete'
        content='Tem certeza que quer deletar?'
        action={
          <Button variant='contained' color='error' onClick={onDeleteRow}>
            Deletar
          </Button>
        }
      />
    </>
  )
}
