import { useCallback, useEffect } from 'react'
// routes
import { paths } from 'src/routes/paths'
import { useRouter } from 'src/routes/hook'
//
import { useAuthContext } from '../hooks'

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: React.ReactNode
}

export default function GuestGuard({ children }: GuestGuardProps) {
  const router = useRouter()

  const { authenticated, user } = useAuthContext()

  const check = useCallback(() => {
    if (authenticated) {
      router.replace(paths.dashboard.budgets.root)
    } else {
      router.replace(paths.auth.login)
    }
  }, [authenticated, router])

  useEffect(() => {
    check()
  }, [check])

  return <>{children}</>
}
